<template>
  <v-expansion-panel class="mt-5">
    <v-expansion-panel-header>
      <v-img
        v-if="editedDrivingItem?.affiliate?.affiliate_data_record"
        alt="Affiliate"
        class="shrink mr-3"
        contain
        src="@/assets/limo-small.png"
        transition="scale-transition"
        height="26px"
        width="26px" />
      <div class="d-flex justify-space-between">
        <span class="text-uppercase mr-1">{{ $t('affiliate.affiliateInfo') + ' ' }} </span>
        <strong v-if="editedDrivingItem?.affiliate?.child_driving_status" class="d-flex">
          <v-icon
            :color="getStatusColor(editedDrivingItem?.affiliate?.child_driving_status)"
            class="me-1 status-circle">
            mdi-circle
          </v-icon>
          {{ getStatus(editedDrivingItem?.affiliate?.child_driving_status) }}
        </strong>
        <strong v-else-if="editedDrivingItem?.affiliate?.status" class="d-flex">
          <v-icon :color="getStatusColor(editedDrivingItem?.affiliate?.status)" class="me-1 status-circle">
            mdi-circle
          </v-icon>
          {{ getStatus(editedDrivingItem?.affiliate?.status) }}
        </strong>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-text-field v-model="getPartnerName" clearable :label="$t('affiliate.partner')" type="text" disabled />
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-text-field
            v-model="editedDrivingItem.affiliate.affiliate_data_record.desired_vehicle_class"
            clearable
            :label="$t('affiliate.preferredVehicleType')"
            type="text"
            disabled />
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" v-if="editedDrivingItem.affiliate.child_driving_id">
          <v-text-field
            v-model="editedDrivingItem.affiliate.child_driving_driver_info"
            clearable
            :label="$t('affiliate.driverInfo')"
            type="text"
            disabled />
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" v-if="editedDrivingItem.affiliate.child_driving_id">
          <v-text-field
            v-model="editedDrivingItem.affiliate.child_driving_vehicle_info"
            clearable
            :label="$t('affiliate.vehicleInfo')"
            type="text"
            disabled />
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-text-field
            v-model="editedDrivingItem.affiliate.affiliate_data_record.price"
            clearable
            :label="$t('affiliate.price')"
            type="text"
            disabled />
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-text-field
            v-model="editedDrivingItem.affiliate.affiliate_data_record.currency"
            clearable
            :label="$t('affiliate.currency')"
            type="text"
            disabled />
        </v-col>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-textarea
            v-model="editedDrivingItem.affiliate.affiliate_data_record.note"
            counter="1000"
            :label="$t('driving.note')"
            rows="2"
            auto-grow
            clearable
            disabled />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          md="12"
          sm="12"
          v-if="
            !affiliateDrivingStatusAccepted &&
            !affiliateDrivingStatusRejected &&
            editedDrivingItem?.affiliate?.parent_driving_id
          "
          class="d-flex justify-end">
          <v-btn class="primary red" text @click="handleDrivingDecision('rejected')">
            {{ $t('buttons.reject') }}
          </v-btn>

          <v-btn class="primary ml-5" text @click="handleDrivingDecision('accepted')">
            {{ $t('buttons.accept') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>

    <v-dialog v-model="affiliateDrivingDecisionModal" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>
          {{ $t('driving.areYouSure') }}
        </v-toolbar>
        <v-card-text>
          <br />
          {{
            affiliateDrivingDecision === 'accepted'
              ? $t('driving.acceptDriving')
              : affiliateDrivingDecision === 'rejected'
              ? $t('driving.rejectDriving')
              : ''
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary cancel" text @click="affiliateDrivingDecisionModal = false">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="saveDrivingDecision" :disabled="loading" :loading="loading">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
import i18n from '@/i18n/i18n';

export default {
  name: 'AffiliateInfo',
  components: {},
  props: [
    'editedDrivingItem',
    'filteredCurrencies',
    'affiliateDrivingStatusAccepted',
    'affiliateDrivingStatusRejected',
  ],
  data: () => ({
    affiliateDrivingDecisionModal: false,
    affiliateDrivingDecision: '',
    loading: false,
  }),
  mounted() {},
  created() {},
  computed: {
    getPartnerName() {
      return this.editedDrivingItem?.affiliate?.parent_driving_id
        ? this.editedDrivingItem?.affiliate?.parent_driving_organisation_name
        : this.editedDrivingItem?.affiliate?.child_driving_id
        ? this.editedDrivingItem?.affiliate?.child_driving_organisation_name
        : '';
    },
  },
  methods: {
    handleDrivingDecision(decision) {
      this.affiliateDrivingDecisionModal = true;
      this.affiliateDrivingDecision = decision;
    },
    async saveDrivingDecision() {
      this.loading = true;

      await this.$store
        .dispatch('affiliate/affiliateDrivingDecision', {
          status: this.affiliateDrivingDecision,
          driving_id: this.editedDrivingItem.id,
        })
        .then(() => {
          if (this.affiliateDrivingDecision === 'accepted') {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.drivingAccepted'), color: 'green' });
            this.$emit('affiliateDrivingStatusAccepted');
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.drivingRejected'), color: 'green' });
            this.$emit('affiliateDrivingStatusRejected');
          }

          this.affiliateDrivingDecisionModal = false;
          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatus(status) {
      const statuses = {
        pending: 'drivingStatus.pending',
        accepted: 'drivingStatus.accepted',
        rejected: 'drivingStatus.rejected',
        canceled: 'drivingStatus.canceled',
      };

      if (this.affiliateDrivingStatusAccepted) {
        return i18n.t(statuses['accepted']);
      } else if (this.affiliateDrivingStatusRejected) {
        return i18n.t(statuses['rejected']);
      } else {
        return status ? i18n.t(statuses[status]) : '';
      }
    },
    getStatusColor(status) {
      const statuses = {
        pending: '#ECC600',
        accepted: '#3CB503',
        rejected: '#E1A750',
        canceled: '#868686',
      };

      if (this.affiliateDrivingStatusAccepted) {
        return statuses['accepted'];
      } else if (this.affiliateDrivingStatusRejected) {
        return statuses['rejected'];
      } else {
        return status ? statuses[status] : '';
      }
    },
  },
  watch: {},
};
</script>
