<template>
  <v-container class="pa-7">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-card class="mx-auto" outlined>
          <v-data-table
            :headers="headers"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="newCheckpoints"
            class="elevation-1"
            fixed-header
            :footer-props="{
              itemsPerPageText: $t('tables.checkpointsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }">
            <v-divider inset></v-divider>
            <template v-slot:item="{ item, index }">
              <tr class="large-table" v-if="windowWidth >= 600">
                <td>
                  <gmap-autocomplete
                    :options="googleMapsOptions"
                    :setFieldsTo="GoogleApiFields"
                    :value="item.checkpoint_location"
                    selectFirstOnEnter
                    :slot-ref-name="'input' + index"
                    @place_changed="(input) => selectCheckpointLocation(input, index)">
                    <template v-slot:default="slotProps">
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                        :name="'location' + index"
                        :custom-messages="{
                          required: $t('customValidations.fieldRequired', {
                            field: $t('names.location'),
                          }),
                        }">
                        <v-text-field
                          :ref="'input' + index"
                          clearable
                          :autofocus="item.checkpoint_location_name ? false : true"
                          v-model="item.checkpoint_location_name"
                          :disabled="disable"
                          @focusout="(input) => selectCheckpointLocationFocusout(input, index)"
                          v-on:attrs="slotProps.attrs"
                          v-on:listeners="slotProps.listeners"
                          :error-messages="errors"
                          @keydown.enter.prevent>
                        </v-text-field>
                      </validation-provider>
                    </template>
                  </gmap-autocomplete>
                </td>
                <td>
                  <v-menu
                    :ref="'arrivalTime' + index"
                    v-model="arrivalTime[index]"
                    :close-on-content-click="false"
                    :return-value.sync="item.arrival_time"
                    min-width="auto"
                    offset-y
                    transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.arrival_time"
                        clearable
                        :disabled="disable"
                        prepend-icon="mdi-clock-outline"
                        readonly
                        single-line
                        v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <DatePicker
                      id="arrivalTime"
                      v-model="item.arrival_time"
                      :minute-increment="1"
                      :model-config="timeModelConfig"
                      class="no-border"
                      is24hr
                      :first-day-of-week="firstDay"
                      mode="time">
                    </DatePicker>
                    <div class="date-picker-buttons">
                      <v-btn class="primary cancel mx-2" elevation="2" text @click="closeArrivalTime(index)">
                        {{ $t('buttons.cancel') }}
                      </v-btn>
                      <v-btn class="primary mx-2" elevation="2" text @click="updateArrivalTime(index)">
                        {{ $t('buttons.confirm') }}
                      </v-btn>
                    </div>
                  </v-menu>
                </td>
                <td class="text-center">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mx-1"
                        color="red darken-3"
                        small
                        v-bind="attrs"
                        @click="deleteCheckpoint(item)"
                        v-on="on"
                        :disabled="disable">
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </td>
              </tr>

              <tr class="mobile-table" v-if="windowWidth < 600">
                <th>{{ $t('checkpointsHeaders.checkpointLocation') }}</th>
                <td>
                  <gmap-autocomplete
                    :options="googleMapsOptions"
                    :setFieldsTo="GoogleApiFields"
                    :value="item.checkpoint_location"
                    selectFirstOnEnter
                    :slot-ref-name="'input' + index"
                    @place_changed="(input) => selectCheckpointLocation(input, index)">
                    <template v-slot:default="slotProps">
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                        :name="'location' + index"
                        :custom-messages="{
                          required: $t('customValidations.fieldRequired', {
                            field: $t('names.location'),
                          }),
                        }">
                        <v-text-field
                          :ref="'input' + index"
                          clearable
                          :autofocus="item.checkpoint_location_name ? false : true"
                          v-model="item.checkpoint_location_name"
                          :disabled="disable"
                          @focusout="(input) => selectCheckpointLocationFocusout(input, index)"
                          v-on:attrs="slotProps.attrs"
                          v-on:listeners="slotProps.listeners"
                          :error-messages="errors"
                          @keydown.enter.prevent>
                        </v-text-field>
                      </validation-provider>
                    </template>
                  </gmap-autocomplete>
                </td>
              </tr>
              <tr class="mobile-table" v-if="windowWidth < 600">
                <th>{{ $t('checkpointsHeaders.arrivalTime') }}</th>
                <td>
                  <v-menu
                    :ref="'arrivalTime' + index"
                    v-model="arrivalTime[index]"
                    :close-on-content-click="false"
                    :return-value.sync="item.arrival_time"
                    min-width="auto"
                    offset-y
                    transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.arrival_time"
                        clearable
                        :disabled="disable"
                        readonly
                        single-line
                        v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <DatePicker
                      id="arrivalTime"
                      v-model="item.arrival_time"
                      :minute-increment="1"
                      :model-config="timeModelConfig"
                      class="no-border"
                      is24hr
                      :first-day-of-week="firstDay"
                      mode="time">
                    </DatePicker>
                    <div class="date-picker-buttons">
                      <v-btn class="primary cancel mx-2" elevation="2" text @click="closeArrivalTime(index)">
                        {{ $t('buttons.cancel') }}
                      </v-btn>
                      <v-btn class="primary mx-2" elevation="2" text @click="updateArrivalTime(index)">
                        {{ $t('buttons.confirm') }}
                      </v-btn>
                    </div>
                  </v-menu>
                </td>
              </tr>
              <tr class="mobile-table" v-if="windowWidth < 600">
                <th style="width: 100%">{{ $t('checkpointsHeaders.actions') }}</th>
                <td class="text-right">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mx-1"
                        color="red darken-3"
                        small
                        v-bind="attrs"
                        @click="deleteCheckpoint(item)"
                        v-on="on"
                        :disabled="disable">
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>

            <template v-slot:footer>
              <v-toolbar flat color="white">
                <v-btn depressed type="button" color="primary" @click="addNew" class="my-1" :disabled="disable">
                  {{ $t('driving.addCheckpoint') }}
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="checkpointDialogDelete" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ checkpointToDelete?.checkpoint_location?.name ? checkpointToDelete?.checkpoint_location?.name : '' }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="checkpointDialogDelete = false"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('driving.deleteCheckpoint') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary" text @click="deleteCheckpointConfirm">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { DatePicker } from 'v-calendar/src/components';

export default {
  name: 'AddCheckpoints',
  props: ['editedDrivingItem', 'headers', 'checkpoints', 'disable', 'googleMapsOptions', 'GoogleApiFields'],
  data: () => ({
    defaultItem: {
      checkpoint_location: '',
      arrival_time: '',
      order_number: 1,
    },
    newCheckpoints: [],
    checkpointDialogDelete: false,
    checkpointToDelete: {},
    indexOfCheckpointToDelete: null,
    formValid: true,
    loading: false,
    arrivalTime: [],
    timeModelConfig: {
      type: 'string',
      mask: 'HH:mm',
    },
    windowWidth: 0,
  }),

  components: { DatePicker },

  created() {
    this.newCheckpoints = [...this.checkpoints];
  },

  methods: {
    addNew() {
      const addObj = Object.assign(
        {},
        {
          ...this.defaultItem,
          // we must increase order number by 1 every time we create a new checkpoint, in order to know checkpoints order
          order_number: this?.newCheckpoints[this?.newCheckpoints?.length - 1]?.order_number
            ? this.newCheckpoints[this?.newCheckpoints?.length - 1].order_number + 1
            : 1,
        }
      );
      this.newCheckpoints.push(addObj);
      this.$emit('updateCheckpoints', this.newCheckpoints);
    },
    deleteCheckpoint(checkpoint) {
      const index = this.newCheckpoints.indexOf(checkpoint);
      this.checkpointToDelete = checkpoint;
      this.indexOfCheckpointToDelete = index;
      this.checkpointDialogDelete = true;
    },
    async deleteCheckpointConfirm() {
      // if (this.checkpointToDelete?.id) {
      //   await this.$store
      //     .dispatch('drivings/deleteDrivingCheckpoint', this.checkpointToDelete)
      //     .then(() => {
      //       this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
      //       this.checkpointDialogDelete = false;
      //       this.$emit('deleteCheckpoint', this.indexOfCheckpointToDelete);
      //     })
      //     .catch(() => {
      //       this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      //     });
      // } else {
      //   this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
      //   this.checkpointDialogDelete = false;
      //   this.$emit('deleteCheckpoint', this.indexOfCheckpointToDelete);
      // }
      this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
      this.checkpointDialogDelete = false;
      this.$emit('deleteCheckpoint', this.indexOfCheckpointToDelete);
    },

    selectCheckpointLocation(input, index) {
      this.newCheckpoints[index].checkpoint_location = input;
      this.newCheckpoints[index].checkpoint_location_name = input.name + ` (${input.formatted_address})`;
      this.newCheckpoints[index].geometry = input.geometry;

      this.$emit('calculateDistance');
    },
    selectCheckpointLocationFocusout(input, index) {
      let value = input.target.value;
      if (
        value !== this.newCheckpoints[index].checkpoint_location?.name &&
        value !==
          this.newCheckpoints[index].checkpoint_location?.name +
            ` (${this.newCheckpoints[index].checkpoint_location?.formatted_address})`
      ) {
        this.newCheckpoints[index].checkpoint_location = { name: value };
        this.newCheckpoints[index].checkpoint_location_name = value;
      }
    },
    updateArrivalTime(index) {
      this.$refs['arrivalTime' + index].save(this.checkpoints[index].arrival_time);
    },
    closeArrivalTime(index) {
      this.arrivalTime.splice(index, 1, false);
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },

  mounted() {
    this.updateWindowWidth(); // Set initial width
    window.addEventListener('resize', this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },

  watch: {
    checkpoints: function (value) {
      this.newCheckpoints = [...value];
    },
  },
};
</script>
