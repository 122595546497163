import { getHttpClient } from './client';

export const getAllInvoices = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/invoice';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getInvoice = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/invoice/' + data).then((response) => response.data);
};

export const saveInvoice = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/invoice', 'POST', data).then((response) => response.data);
};

export const deleteInvoice = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/invoice/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const generateInvoicePaymentLink = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/stripe/generate-invoice-checkout-link';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};

export const markInvoiceAsPaid = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/set-invoice-as-paid/' + data.invoice_id;
  return await getHttpClient(baseUrl, 'GET', data).then((response) => response.data);
};
