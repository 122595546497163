import {
  getAllInvoices,
  getInvoice,
  saveInvoice,
  deleteInvoice,
  generateInvoicePaymentLink,
  markInvoiceAsPaid,
} from '@/api/invoices';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllInvoices(state, item) {
      return getAllInvoices(item);
    },
    async getInvoice(state, item) {
      return getInvoice(item);
    },
    async saveInvoice(state, item) {
      return saveInvoice(item);
    },
    async deleteInvoice(state, item) {
      return deleteInvoice(item);
    },
    async generateInvoicePaymentLink(state, item) {
      return generateInvoicePaymentLink(item);
    },
    async markInvoiceAsPaid(state, item) {
      return markInvoiceAsPaid(item);
    },
  },
  getters: {},
};
