import { getHttpClient } from './client';

// Saas admin sees all packages
export const getAllPackages = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/all-subscription-types').then((response) => response.data);
};

export const getAllPricingRegions = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/price-region-by-user').then((response) => response.data);
};

// Packages types for register form
export const getPackagesTypes = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/subscription-types').then((response) => response.data);
};

export const getCalculatedPrice = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/calculate-price', 'POST').then(
    (response) => response.data
  );
};

export const getChargeDate = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/charge-date').then((response) => response.data);
};

export const changePackage = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/change-subscription', 'POST', data).then(
    (response) => response.data
  );
};

export const getCurrentPackage = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/get-current-subscription/' + data.id).then(
    (response) => response.data
  );
};

export const cancelPackage = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/cancel-subscription', 'POST', data).then(
    (response) => response.data
  );
};

export const checkPackageChangeAvailability = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/change-package-limit/' + data.id).then(
    (response) => response.data
  );
};
