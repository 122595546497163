import { getVisitedSteps, sendVisitedStep } from '@/api/onboardingTutorial';

export default {
  namespaced: true,
  state: {
    tourActive: false,
    tourReset: false,
  },
  mutations: {
    SET_TOUR_ACTIVE(state, isActive) {
      state.tourActive = isActive;
    },
    SET_TOUR_RESET(state, status) {
      state.tourReset = status;
    },
  },
  actions: {
    toggleTour({ commit }, isActive) {
      commit('SET_TOUR_ACTIVE', isActive);
    },
    async getVisitedSteps() {
      return getVisitedSteps();
    },
    async sendVisitedStep(state, item) {
      return sendVisitedStep(item);
    },
    resetTour({ commit }) {
      commit('SET_TOUR_RESET', true);
    },
    clearTourReset({ commit }) {
      commit('SET_TOUR_RESET', false);
    },
  },
  getters: {
    isTourActive(state) {
      return state.tourActive;
    },
    isTourReset(state) {
      return state.tourReset;
    },
  },
};
