import {
  getAllPricingZones,
  getPricingZone,
  savePricingZone,
  updatePricingZone,
  deletePricingZone,
  getAllPricingZoneRelations,
  getPricingZoneRelation,
  savePricingZoneRelation,
  updatePricingZoneRelation,
  deletePricingZoneRelation,
  calculatePriceViaZonePricing,
  calculatePriceViaZonePricingCustomer,
} from '@/api/zonePricing';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllPricingZones(state, item) {
      return getAllPricingZones(item);
    },
    async getPricingZone(state, item) {
      return getPricingZone(item);
    },
    async savePricingZone(state, item) {
      return savePricingZone(item);
    },
    async updatePricingZone(state, item) {
      return updatePricingZone(item);
    },
    async deletePricingZone(state, item) {
      return deletePricingZone(item);
    },
    async getAllPricingZoneRelations(state, item) {
      return getAllPricingZoneRelations(item);
    },
    async getPricingZoneRelation(state, item) {
      return getPricingZoneRelation(item);
    },
    async savePricingZoneRelation(state, item) {
      return savePricingZoneRelation(item);
    },
    async updatePricingZoneRelation(state, item) {
      return updatePricingZoneRelation(item);
    },
    async deletePricingZoneRelation(state, item) {
      return deletePricingZoneRelation(item);
    },
    async calculatePriceViaZonePricing(state, item) {
      return calculatePriceViaZonePricing(item);
    },
    async calculatePriceViaZonePricingCustomer(state, item) {
      return calculatePriceViaZonePricingCustomer(item);
    },
  },
  getters: {},
};
