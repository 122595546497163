import { getFirstDayOfWeek } from '../utils/calendarUtils';

export default {
  computed: {
    firstDay() {
      return getFirstDayOfWeek() === 'sunday' ? 1 : 2;
    },

    weekDays() {
      return getFirstDayOfWeek() === 'sunday' ? [0, 1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 5, 6, 0];
    },
  },
};
