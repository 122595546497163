import {
  assignSaasAdminOrganisation,
  deleteOrganisation,
  getAllOrganisations,
  getAllOrganisationsForSales,
  getOrganisation,
  loadOrganisationViewOnlyData,
  saveOrganisation,
  toggleOrganisationPublicRoutes,
  toggleOrganisationPublicLink,
  toggleOrganisationsActiveStatus,
  updateOrganisation,
  deleteOrganizationImage,
  deleteOrganizationSignature,
  deleteOrganizationSeal,
  getAllReferents,
  enableStripeConnect,
  disableStripeConnect,
  exportData,
} from '@/api/organisations';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllOrganisations() {
      return getAllOrganisations();
    },
    async getAllOrganisationsForSales() {
      return getAllOrganisationsForSales();
    },
    async getOrganisation(state, item) {
      return getOrganisation(item);
    },
    async saveOrganisation(state, item) {
      return saveOrganisation(item);
    },
    async updateOrganisation(state, item) {
      return updateOrganisation(item);
    },
    async deleteOrganisation(state, item) {
      return deleteOrganisation(item);
    },
    async loadOrganisationViewOnlyData(state, item) {
      return loadOrganisationViewOnlyData(item);
    },
    async toggleOrganisationsActiveStatus(state, item) {
      return toggleOrganisationsActiveStatus(item);
    },
    async assignSaasAdminOrganisation(state, item) {
      return assignSaasAdminOrganisation(item);
    },
    async toggleOrganisationPublicRoutes() {
      return toggleOrganisationPublicRoutes();
    },
    async toggleOrganisationPublicLink() {
      return toggleOrganisationPublicLink();
    },
    async deleteOrganizationImage(state, item) {
      return deleteOrganizationImage(item);
    },
    async deleteOrganizationSignature(state, item) {
      return deleteOrganizationSignature(item);
    },
    async deleteOrganizationSeal(state, item) {
      return deleteOrganizationSeal(item);
    },
    async getAllReferents(state, item) {
      return getAllReferents(item);
    },
    async enableStripeConnect(state, item) {
      return enableStripeConnect(item);
    },
    async disableStripeConnect(state, item) {
      return disableStripeConnect(item);
    },
    async exportData(state, item) {
      return exportData(item);
    },
  },
  getters: {},
};
