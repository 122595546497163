<template>
  <v-dialog v-model="profileDialog" max-width="700px">
    <template v-slot:activator="{ attrs }">
      <v-list v-bind="attrs" @click.native="profileDialog = true" :disabled="tourActive">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="profile__info">
              <div v-if="profileImage" class="img-wrapper">
                <img :src="profileImage" alt="Profile Image" class="profile__image" />
              </div>
              <span>
                {{ user.profile.first_name + ' ' + user.profile.last_name }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <v-card>
      <v-card-title class="info title white--text font-weight-bold">
        {{ $t('profile.yourProfile') }}
        <v-spacer />
        <v-icon class="mdi mdi-close" style="color: white" @click="profileDialog = false"></v-icon>
      </v-card-title>
      <v-card-text class="mt-6">
        <v-container>
          <v-row>
            <v-col v-if="profileImage" cols="12" lg="12" md="12" sm="12" class="my-2">
              <img :src="profileImage" alt="Profile Image" class="profile__image" />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6" class="info__wrapper my-2">
              <span class="bold">
                {{ $t('profile.name') }}
              </span>
              <span>
                {{ user.profile.first_name + ' ' + user.profile.last_name }}
              </span>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6" class="info__wrapper my-2">
              <span class="bold">
                {{ $t('profile.role') }}
              </span>
              <span>
                {{ user.role.name }}
              </span>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6" class="info__wrapper my-2">
              <span class="bold">
                {{ $t('profile.username') }}
              </span>
              <span>
                {{ user.username }}
              </span>
            </v-col>
            <v-col v-if="user.password" cols="12" lg="6" md="6" sm="6" class="info__wrapper my-2">
              <span class="bold">
                {{ $t('profile.password') }}
              </span>
              <span>
                {{ user.password }}
              </span>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6" class="info__wrapper my-2">
              <span class="bold">
                {{ $t('profile.phoneNumber') }}
              </span>
              <span>
                {{ user.profile.phone }}
              </span>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6" class="info__wrapper my-2">
              <span class="bold">
                {{ $t('profile.email') }}
              </span>
              <span>
                {{ user.email }}
              </span>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6" class="info__wrapper my-2">
              <span class="bold">
                {{ $t('profile.percentage') }}
              </span>
              <span>
                {{ user.percentage }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProfileDialog',
  props: ['user'],
  created() {

    if (this.user?.profile?.picture_path && !this.user.profile.picture_path.includes("https://") && !this.user.profile.picture_path.includes("http://")) {
      this.profileImage = (process.env.VUE_APP_BACKEND_URL + this.user.profile.picture_path).replace('public', 'storage');
    }else{
      this.profileImage = this.user.profile.picture_path ?? '';
    }

  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
  },
  data() {
    return {
      routePrefix: process.env.VUE_APP_ROUTE_PREFIX,
      profileImage: null,
      profileDialog: false,
    };
  },
  methods: {
    showProfileDialog() {
      this.profileDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.profile__info {
  display: flex;
  align-items: center;

  .img-wrapper {
    width: 30px;
    height: 30px;
    margin-right: 1rem;
  }
}

.img-wrapper {
  height: 200px;
  width: auto;

  .profile__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.profile__image {
  height: 200px;
  width: auto;
}

.info__wrapper {
  span {
    display: block;
    color: #1b1d20;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */

    &.bold {
      color: #444;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 88.889% */
      margin-bottom: 1rem;
    }
  }
}
</style>
