import { getAllDrivings } from '@/api/availability';

export default {
  namespaced: true,
  state: {
    drivers: [],
    vehicles: [],
  },
  mutations: {
    SET_DRIVERS(state, value) {
      state.drivers = value;
    },
    SET_VEHICLES(state, value) {
      state.vehicles = value;
    },
  },
  actions: {
    async getAllDrivings(state, item) {
      return getAllDrivings(item);
    },
    saveDrivers({ commit, dispatch, state }, value) {
      commit('SET_DRIVERS', value);
      dispatch('saveAvailabilityData', { drivers: state.drivers, vehicles: state.vehicles });
    },
    saveVehicles({ commit, dispatch, state }, value) {
      commit('SET_VEHICLES', value);
      dispatch('saveAvailabilityData', { drivers: state.drivers, vehicles: state.vehicles });
    },
    saveAvailabilityData(_, value) {
      // Save the combined data to localStorage
      localStorage.setItem('availabilityData', JSON.stringify(value));
    },
    loadAvailabilityData({ commit }) {
      // Load data from localStorage if available
      const data = localStorage.getItem('availabilityData')
        ? JSON.parse(localStorage.getItem('availabilityData'))
        : null;
      if (data) {
        commit('SET_DRIVERS', data.drivers);
        commit('SET_VEHICLES', data.vehicles);
      }
    },
  },
  getters: {
    getDrivers(state) {
      return state.drivers;
    },
    getVehicles(state) {
      return state.vehicles;
    },
  },
};
