export default {
  sidebar: {
    fleet: 'Флота',
    vehicles: 'Возила',
    vehiclesClasses: 'Класе возила',
    administration: 'Управљање',
    currencies: 'Валуте',
    currenciesAdministration: 'Управљање валутама',
    paymentTypes: 'Начини плаћања',
    paymentTypesAdministration: 'Управљање начинима плаћања',
    users: 'Корисници',
    usersAdministration: 'Управљање корисницима',
    clients: 'Клијенти',
    clientsAdministration: 'Управљање клијентима',
    organizations: 'Организације',
    organizationsAdministration: 'Управљање организацијама',
    saasAdmin: 'Saas админ',
    administrators: 'Администратори',
    saasAdminAdministration: 'Управљање Saas админом',
    statistics: 'Статистика',
    generalOverview: 'Општи преглед',
    payment: 'Плаћање',
    rebates: 'Попусти',
    pricingRegions: 'Ценовне регије',
    pricingRegionsAdministration: 'Администрирање ценовних региона',
    help: 'Помоћ',
    drivings: 'Налози',
    calendar: 'Календар',
    tabularView: 'Табеларни приказ',
    expenses: 'Трошкови',
    vehicleExpensesAdministration: 'Управљање трошковима возила',
    vehicleMaintenance: 'Одржавање возила',
    vehicleMaintenanceAdministration: 'Управљање одржавањем возила',
    organization: 'Организација',
    organizationAdminstration: 'Управљање организацијом',
    packages: 'Пакети',
    packagesChange: 'Промена пакета',
    logout: 'Одјава',
    logoutText: 'Да ли сте сигурни да желите да се одјавите?',
    unaccessibleRoutes: 'Не можете приступити овој страници. Ажурирајте пакет за потпун приступ.',
    transactions: 'Трансакције',
    gpsMap: 'GPS мапа',
    team: 'Тим',
    teamAdministration: 'Администрација тима',
    invoices: 'Фактуре',
    invoicesAdministration: 'Администрација фактура',
    drivingOffers: 'Понуде',
    drivingOffersAdministration: 'Администрација понуда',
    dashboard: 'Почетна страна',
    dashboardAdministration: 'Администрација почетне стране',
    resourceOverview: 'Преглед ресурса',
    resourceOverviewAdministration: 'Администрација прегледа ресурса',
    rides: 'Вожње',
    passengerReviews: 'Рецензије путника',
    passengerReviewsAdministration: 'Администрација рецензија путника',
    zonePricing: 'Ценовник зона',
    pricingZones: 'Ценовне зоне',
    pricingZonesAdministration: 'Администрација ценовних зона',
    pricingZoneRelations: 'Повезивање ценовних зона',
    pricingZoneRelationsAdministration: 'Администрација повезивања ценовних зона',
    availability: 'Доступност',
    availabilityAdministration: 'Доступност возача и возила',
  },
  header: {
    organization: 'Организација',
    newDriving: 'Налог',
    dailyRent: 'Дневни најам',
    transfer: 'Пренос',
    notifications: 'Обавештења',
    changeLanguage: 'Промени језик',
    help: 'Помоћ',
  },
  footer: {
    poweredBy: 'Покреће',
    subscriptionTo: 'Ваша претплата на',
    trialEndsAt: 'Ваш пробни период за',
    packageExpiresIn: 'претплата истиче за',
    clickToChangePackage: 'Кликните за промену пакета',
    clickToActivatePackage: 'Кликните да активирате пакет',
    clickToPay: 'Кликните за плаћање',
    packageHasExpired: 'пакет је истекла',
    affiliateUser: 'Ако желите да откључате све функционалности платформе, неопходно је да се претплатите ',
    here: 'ОВДЕ',
  },
  buttons: {
    ok: 'ОК',
    yes: 'Да',
    no: 'Не',
    save: 'Сачувај',
    send: 'Пошаљи',
    sendMessage: 'Пошаљи поруку',
    edit: 'Измени',
    add: 'Додај',
    confirm: 'Потврди',
    cancel: 'Откажи',
    cancelDriving: 'Откажи налог',
    markAsDone: 'Означи као завршено',
    markAsNoShow: 'Означи као недолазак',
    deleteDriving: 'Избриши налог',
    newOrganisation: 'Нова организација',
    changePackage: 'Промена пакета',
    downloadStats: 'Преузми статистику',
    downloadPDF: 'Преузми PDF',
    today: 'Данас',
    choose: 'Изабери',
    login: 'Пријава',
    generateFile: 'Генериши уговор',
    generateDrivingOrder: 'Штампај налог',
    downloadFile: 'Преузми фајл',
    activate: 'Активирај',
    deactivate: 'Деактивирај',
    showImage: 'Прикажи слику',
    showDetails: 'Прикажи детаље',
    actions: 'Акције',
    delete: 'Обриши',
    open: 'Отвори',
    show: 'Прикажи',
    options: 'Опције',
    prev: 'Претходно',
    next: 'Следеће',
    skip: 'Прескочи',
    finish: 'Заврши',
    settings: 'Подешавања',
    cancelSubscription: 'Откажи претплату',
    backToEditOrganization: 'Назад на уређивање организације',
    payNow: 'Платите сада',
    back: 'Иди',
    integrationRoutes: 'Интеграциони путеви',
    transactions: 'Трансакције',
    articlesOfLaw: 'Чланови закона',
    export: 'Извоз',
    duplicate: 'Направи копију',
    makeReturnTrip: 'Направите повратно путовање',
    selectOrganisation: 'Изаберите организацију',
    advancedSettings: 'Напредна подешавања',
    accept: 'Прихвати',
    reject: 'Одбиј',
    sendViaGnet: 'Пошаљи преко GNET-а',
    printInvoice: 'Штампај фактуру',
    enable: 'Омогући',
    disable: 'Онемогући',
    generatePaymentLink: 'Генериши линк за плаћање',
    addItem: '+ Додај ставку',
    visitWebsite: 'Посетите наш веб сајт',
    buyMoreSms: 'Купите више SMS-ова',
    forward: 'Проследи',
    sendViaAffiliate: 'Пошаљи преко Affiliate',
    markInvoiceAsPaid: 'Означи фактуру као плаћену',
    markDrivingOfferAsPaid: 'Означи понуду вожње као плаћену',
    selectAll: 'Изабери све',
  },
  tooltips: {
    save: 'Сачувај',
    edit: 'Измени',
    delete: 'Обриши',
    showImage: 'Прикажи слику',
    open: 'Отвори',
    show: 'Прикажи',
    showDetails: 'Прикажи детаље ',
    downloadFile: 'Преузми фајл',
    generateFile: 'Генериши уговор',
    generateDrivingOrder: 'Штампај налог',
    activate: 'Активирај',
    deactivate: 'Деактивирај',
    cancel: 'Откажи',
    cancelDriving: 'Откажи налог',
    cannotMarkAsCanceled: 'Naruđba ne može biti označena kao otkazana jer je već prihvaćena',
    markAsDone: 'Означи као завршено',
    cannotMarkAsDone: 'Налог не може бити означен као завршен, јер се још није десио',
    markAsNoShow: 'Означи као недолазак',
    cannotMarkAsNoShow: 'Наредба не може бити означена као недолазак, јер још није реализована',
    deleteDriving: 'Избриши налог',
    cannotDelete: 'Извините, али из безбедносних разлога корисници се не могу брисати док су тренутно пријављени.',
    duplicate: 'Направи копију',
    makeReturnTrip: 'Направите повратно путовање',
    refresh: 'Освежи',
    selectOrganisation: 'Изаберите организацију',
    sendViaGnet: 'Пошаљи преко GNET-а',
    receivedViaGnet: 'Примљено путем GNET-а',
    sentViaGnet: 'Послато путем GNET-а',
    printInvoice: 'Штампај фактуру',
    optionsDisabled: 'Морате да одбијете или прихватите налог да бисте приступили менију опција',
    cannotMakeReturnTrip: 'Не можете направити повратак за дневни најам',
    cannotSendGnetOrderViaGnet: 'Не можете послати налог преко гнета',
    cannotSendViaGnetWithoutGnetId: 'Не можете послати налог преко гнета без гнет ID-а',
    cannotPrintInvoiceWithoutClient: 'Не можете штампати фактуру без избора клијента',
    cannotMarkAsDoneStatusRejected: 'Налог не може бити означен као завршен јер је већ означен као одбијен',
    cannotMarkAsDoneStatusCanceled: 'Налог не може бити означен као завршен јер је већ означен као отказан',
    cannotMarkAsDoneStatusDone: 'Налог не може бити означен као завршен јер је већ означен као завршен',
    cannotMarkAsDoneStatusNoShow: 'Налог не може бити означен као завршен јер је већ означен као ненаплаћен',
    cannotMarkAsNoShowStatusRejected: 'Налог не може бити означен као ненаплаћен јер је већ означен као одбијен',
    cannotMarkAsNoShowStatusCanceled: 'Налог не може бити означен као ненаплаћен јер је већ означен као отказан',
    cannotMarkAsNoShowStatusDone: 'Налог не може бити означен као ненаплаћен јер је већ означен као завршен',
    cannotMarkAsNoShowStatusNoShow: 'Налог не може бити означен као ненаплаћен јер је већ означен као ненаплаћен',
    cannotMarkAsCanceledGnet: 'Налог не може бити означен као отказан јер је примљен преко гнета',
    cannotMarkAsCanceledStatusRejected: 'Налог не може бити означен као отказан јер је већ означен као одбијен',
    cannotMarkAsCanceledStatusExpired: 'Налог не може бити означен као отказан јер је већ истекао',
    cannotMarkAsCanceledStatusCanceled: 'Налог не може бити означен као отказан јер је већ означен као отказан',
    cannotMarkAsCanceledStatusDraft: 'Налог не може бити означен као отказан јер је већ означен као нацрт',
    cannotMarkAsCanceledStatusCompleted: 'Налог не може бити означен као отказан јер је већ означен као завршен',
    cannotMarkAsCanceledStatusNoShow: 'Налог не може бити означен као отказан јер је већ означен као ненаплаћен',
    cannotDeleteDrivingSentViaGnet: 'Налог не може бити избрисан јер је послат преко гнета',
    cannotDeleteDrivingReceivedViaGnet: 'Налог не може бити избрисан јер је примљен преко гнета и прихваћен',
    generatePaymentLink: 'Генериши линк за плаћање',
    cannotGeneratePaymentLInk: 'Линк за плаћање не може бити генерисан јер нисте активирали Stripe',
    disabledExport: 'Можете извозити податке једном у 24 сата',
    sendSms: 'Пошаљи SMS',
    accept: 'Прихвати',
    reject: 'Одбиј',
    sendViaAffiliate: 'Пошаљи преко Affiliate',
    receivedViaAffiliate: 'Примљено преко Affiliate',
    sentViaAffiliate: 'Послато преко Affiliate',
    cannotSendViaAffiliate: 'Не можете послати affiliate налог за вожњу путем affiliate-а.',
    cannotForwardDrivingOrder: 'Не можете проследити налог за вожњу који је у прошлости.',
    cannotMarkAsCanceledAffiliate: 'Налог за вожњу не може бити означен као отказан јер је примљен путем affiliate-а.',
    cannotMarkAsCanceled12Hours: 'Налог не може бити означен као отказан јер је прошло 12 сати од времена преузимања.',
    cannotDeleteActiveDriving: 'Активни налог за вожњу не може бити обрисан.',
    noImageToShow: 'Нема слике за приказ',
    disabledForAffiliateUser:
      "Ваш налог је типа 'Аффилиате'. Ова акција је доступна за претплаћене [Редовне] кориснике. Идите на линк у футеру како би се претплатили.",
    markInvoiceAsPaid: 'Означи фактуру као плаћену',
    markDrivingOfferAsPaid: 'Означи понуду вожње као плаћену',
    invoiceIsPaid: 'Фактура је већ означена као плаћена',
    drivingOfferIsPaid: 'Понуда за вожњу је већ означена као плаћена',
  },
  snackbar: {
    close: 'Затвори',
    savedSuccessfully: 'Успешно сачувано',
    excelDownloadedSuccessfully: 'Успешно преузето',
    deletedSuccessfully: 'Успешно обрисано',
    sentSuccessfully: 'Успешно послато',
    statusChanged: 'Статус успешно промењен',
    activeStatusChanged: 'Статус активности успешно промењен',
    defaultStatusChanged: 'Подразумевани статус успешно промењен',
    primaryStatusChanged: 'Примарни статус успешно промењен',
    anErrorOccured: 'Дошло је до грешке, обавестите подршку',
    loadingCommentsFailed: 'Неуспешно учитавање коментара',
    drivingCanceled: 'Налог је успешно отказан',
    drivingFinished: 'Налог је успешно завршен',
    drivingNoShow: 'Налог је успешно означен као недолазак.',
    drivingDeleted: 'Налог je успешно је обрисанa',
    drivingAccepted: 'Вожња је успешно прихваћена',
    drivingRejected: 'Вожња је успешно одбијена',
    planChanged: 'План успешно промењен',
    planChangeFailed: 'Није могућа промена плана',
    saveChangesFirst: 'Прво сачувајте измене које сте раније направили',
    makeRoutesPublic: 'Маршрути су јавно доступни',
    makeRoutesNotPublic: 'Маршрути више нису јавно доступни',
    makePublicLinkActive: 'Јавна веза је сада доступна',
    makePublicLinkInactive: 'Јавна веза више није доступна',
    integrationKeyCopied: 'Кључ за интеграцију је копиран',
    copyToken: 'Ручно копирајте токен',
    publicLinkCopied: 'Јавна веза је копирана',
    copyPublicLink: 'Ручно копирајте јавну везу',
    organisationChanged: 'Организација успешно промењена',
    changeFailed: 'Промена није успела, покушајте поново',
    loginFailed: 'Корисничко име или лозинка су нетачни',
    organisationSuspended: 'Ваша организација је придодана',
    userEmailDoesNotExists: 'Корисник са овим имејлом не постоји',
    canceledSubscrSuccessfully: 'Успешно сте отказали претплату',
    toManyRequests: 'Превише захтева. Покушајте поново за минут.',
    visibleStatusChanged: 'Статус видљив успјешно измијењен',
    noInternetConnection: 'Нема интернета. Молимо проверите вашу интернет конекцију.',
    backOnline: 'Поново сте повезани на интернет.',
    driverNotifiedToTurnGpsOn: 'Возач је обавештен да укључи GPS',
    savedSuccessfullyWithGnetError: 'GNET синхронизација није успела, налог за вожњу је успешно сачуван.',
    logoutFromAnotherDevice: 'Odjavljeni ste sa drugog uređaja.',
    stripeEnabled: 'Stripe успешно активиран',
    stripeDisabled: 'Stripe успешно деактивиран',
    finishStripeConnect: 'Молимо завршите подешавање Stripe-а',
    requestSentSuccessfully: 'Захтев успешно послат',
    pricingZoneNoMatch: 'Не можемо да пронађемо одговарајуће ценовне зоне за израчунавање цене.',
  },
  filters: {
    filter: 'Филтер',
    currentYear: 'Текућа година',
    lastYear: 'Прошла година',
    lastQuarter: 'Прошли квартал',
    lastThreeYears: 'Последње три године',
    filterByDriver: 'Филтер по возачу',
    filterByVehicle: 'Филтер по возилу',
    filterByClient: 'Филтер по клијенту',
    day: 'Дан',
    week: 'Недеља',
    month: 'Месец',
    vehicle: 'Возило',
    driving: 'Вожња',
    user: 'Корисник',
    fromDate: 'Од датума',
    toDate: 'До датума',
    threeMonths: 'Три месеца',
    halfYear: 'Пола године',
    year: 'Година',
  },
  months: {
    jan: 'Јануар',
    feb: 'Фебруар',
    mar: 'Март',
    apr: 'Април',
    may: 'Мај',
    jun: 'Јун',
    jul: 'Јул',
    aug: 'Август',
    sep: 'Септембар',
    oct: 'Октобар',
    nov: 'Новембар',
    dec: 'Децембар',
    January: 'Јануар',
    February: 'Фебруар',
    March: 'Март',
    April: 'Април',
    May: 'Мај',
    June: 'Јун',
    July: 'Јул',
    August: 'Август',
    September: 'Септембар',
    October: 'Октобар',
    November: 'Новембар',
    December: 'Децембар',
  },
  usersPageHeaders: {
    firstName: 'Име',
    lastName: 'Презиме',
    username: 'Корисничко име',
    email: 'Е-пошта',
    phoneNumber: 'Број телефона',
    role: 'Улога',
    active: 'Активан',
    actions: 'Акције',
  },
  usersRoles: {
    masterAdmin: 'Главни администратор',
    manager: 'Менаџер',
    driver: 'Возач',
    operator: 'Оператор',
    externalAssociate: 'Спољни сарадник',
    sales: 'Продаја',
    salesLead: 'Вођа продаје',
    gnet: 'GNET',
    customer: 'Корисник портала',
    publicUser: 'Јавни корисник',
  },
  masterUsersPageHeaders: {
    firstName: 'Име',
    lastName: 'Презиме',
    username: 'Корисничко име',
    email: 'Е-пошта',
    phoneNumber: 'Број телефона',
    role: 'Улога',
    organisation: 'Организација',
    active: 'Активан',
    actions: 'Акције',
  },
  usersPage: {
    newItem: 'Нови унос корисника',
    editItem: 'Измени корисника',
    id: 'ИД',
    firstName: 'Име',
    lastName: 'Презиме',
    username: 'Корисничко име',
    password: 'Лозинка',
    phoneNumber: 'Број телефона',
    role: 'Улога',
    client: 'Клијент',
    email: 'Е-пошта',
    organisation: 'Организација',
    percentage: 'Проценат',
    externalAssociate: 'Спољни сарадник',
    image: 'Слика',
    deleteUser: 'Обриши корисника',
    deleteMessage: 'Да ли сте сигурни да желите да обришете корисника ',
    emailChanged: 'Промена е-поште',
    verificationEmailSent:
      'Захтев за промену адресе е-поште је послат. Да бисте завршили процес и обезбедили сигурност корисничког налога, нову адресу е-поште морате верификовати кликом на везу за верификацију послату на нову адресу. Адреса е-поште неће бити промењена док не буде успешно верификована.',
    passengersInfoAvailableToDriver: 'Информације о путницима доступне возачу',
  },
  clientsPageHeaders: {
    name: 'Име',
    phoneNumber: 'Број телефона',
    address: 'Адреса',
    email: 'Е-пошта',
    companyId: 'ПИБ/Лични ID',
    clientType: 'Тип клијента',
    percentage: 'Проценат',
    active: 'Активан',
    actions: 'Акције',
  },
  clientsPage: {
    newItem: 'Нови унос клијента',
    editItem: 'Измени клијента',
    id: 'ИД',
    name: 'Име',
    address: 'Адреса',
    phoneNumber: 'Број телефона',
    email: 'Е-пошта',
    companyId: 'ПИБ/Лични ID',
    clientType: 'Тип клијента',
    individual: 'Физичко лице',
    business: 'Правно лице',
    percentage: 'Проценат',
    deleteClient: 'Обриши клијента',
    deleteMessage: 'Да ли сте сигурни да желите да обришете клијента ',
    tabs: {
      general: 'Опште',
      transfers: 'Трансфери',
      users: 'Корисници',
      invoices: 'Фактуре',
      cards: 'Картице',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Број налога за вожњу',
    drivingType: 'Тип резервације',
    drivingStatus: 'Статус',
    locationFrom: 'Почетна тачка',
    locationTo: 'Дестинација',
    vehicleClass: 'Класа возила',
    vehicle: 'Возило',
    driversName: 'Возач',
    startTime: 'Време подизања',
    actions: 'Акције',
    exportExcel: 'Извоз ексел',
    price: 'Цена',
    totalPrice: 'Укупна цена',
    confirmed: 'Потврђено',
    paid: 'Плаћено',
    numberOfDrivings: 'Број вожњи',
  },
  vehiclesHeaders: {
    plateNumber: 'Број регистрације',
    brand: ' Марка возила',
    vehicleClass: 'Класа возила',
    passengersNumber: 'Број путника',
    fuelConsumption: 'Потрошња горива',
    yearOfManufacture: 'Година производње',
    pricePerKm: 'Цена по км',
    pricePerMi: 'Цена по миљи',
    pricePerHour: 'Цена по сату',
    pricePerWaitingHour: 'Цена по сату чекања',
    active: 'Активан',
    actions: 'Акције',
  },
  vehiclesPage: {
    id: 'ИД',
    newItem: 'Нови унос возила',
    editItem: 'Измена возила',
    vehiclePlates: 'Регистарски бројеви',
    vehicleBrand: 'Марка возила',
    vehicleClass: 'Класа возила',
    passengersNumber: 'Број путника',
    fuelConsumption: 'Потрошња горива (на 100 км)',
    fuelConsumptionMi: 'Потрошња горива аутомобила (на 100 миља)',
    manufactureYear: 'Година производње',
    price: 'Цена по км',
    priceMi: 'Цена по миљи',
    hourPrice: 'Цена по сату',
    image: 'Слика',
    hourWaitingPrice: 'Цена по сату чекања',
    deleteMessage: 'Да ли сте сигурни да желите да обришете ово возило?',
    tabs: {
      general: 'Генерално',
      transfers: 'Трансфери',
      costs: 'Трошкови',
      damages: 'Штете',
    },
    licence: 'Број лиценце',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Класа возила',
    numberOfPassengers: 'Број путника',
    numberOfSuitcases: 'Број кофера',
    availableForPublicBookingForm: 'Доступно за јавну booking резервацију',
    active: 'Активно',
    actions: 'Акције',
  },
  vehicleClassesPage: {
    newItem: 'Нови унос класе возила',
    editItem: 'Измена класе возила',
    id: 'ИД',
    vehicleClass: 'Класа возила',
    numberOfPassengers: 'Број путника',
    numberOfSuitcases: 'Број кофера',
    availableForPublicBookingForm: 'Доступно за јавну booking резервацију',
    image: 'Слика',
    deleteMessage: 'Да ли сте сигурни да желите да обришете ову класу возила?',
  },
  expensesHeaders: {
    name: 'Назив',
    vehicle: 'Возило',
    type: 'Тип',
    description: 'Опис',
    price: 'Цена',
    expenseDate: 'Датум',
    actions: 'Акција',
    driving: 'Налог ',
    exportExcel: 'Извоз ексел',
  },
  vehicleExpensesTypes: {
    minorService: 'Мали сервис',
    majorService: 'Велики сервис',
    fuel: 'Гориво',
    tires: 'Гуме',
    parking: 'Паркинг',
    other: 'Остало',
  },
  expensesPage: {
    newItem: 'Нови унос трошка',
    editItem: 'Измена трошка',
    id: 'ИД',
    name: 'Назив',
    vehicle: 'Возило',
    costType: 'Тип трошка',
    price: 'Цена',
    currency: 'Валута',
    costTime: 'Време трошка',
    description: 'Опис',
    deleteMessage: 'Да ли сте сигурни да желите да обришете ово трошак?',
    costTypes: {
      'Mali servis': 'Мали сервис',
      'Veliki servis': 'Велики сервис',
      Gorivo: 'Гориво',
      Gume: 'Гуме',
      Parking: 'Паркинг',
      Ostalo: 'Остало',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Возило',
    details: 'Детаљи',
    dateFrom: 'Датум од',
    dateTo: 'Датум до',
    actions: 'Акције',
  },
  vehicleMaintenancePage: {
    newItem: 'Нови унос квара на возилу',
    editItem: 'Измена квара на возилу',
    id: 'ИД',
    vehicle: 'Возило',
    description: 'Опис',
    dateFrom: 'Време од',
    dateTo: 'Време до',
    deleteMessage: 'Да ли сте сигурни да желите да обришете овај квар на возилу?',
  },
  rebatesHeaders: {
    min: 'Минималан број налога за вожњу',
    max: 'Максималан број налога за вожњу',
    percentage: 'Рабат (%)',
    actions: 'Акције',
  },
  rebatesPage: {
    newItem: 'Нови унос рабата',
    editItem: 'Измена рабата',
    rebateMin: 'Минималан број налога за вожњу',
    rebateMax: 'Максималан број налога за вожњу',
    rebate: 'Рабат (%)',
    deleteRebate: 'Обриши попуст',
    deleteMessage: 'Да ли сте сигурни да желите да обришете ову рабату?',
  },
  customerCardsHeaders: {
    cardholderName: 'Име власника картице',
    cardNumber: 'Број картице',
    expiryDate: 'Датум истека',
    cvv: 'CVV',
    actions: 'Акције',
  },
  customerCardsPage: {
    newItem: 'Нови унос картице',
    editItem: 'Измена картице',
    cardholderName: 'Име власника картице',
    cardNumber: 'Број картице',
    expiryDate: 'Датум истека',
    cvv: 'CVV',
    deleteCard: 'Обриши картицу',
    deleteMessage: 'Да ли сте сигурни да желите да обришете ову картицу?',
  },
  pricingRegionsHeaders: {
    name: 'Име',
    actions: 'Акције',
  },
  pricingRegionsPage: {
    newItem: 'Нова ставка цене региона',
    editItem: 'Измена цене региона',
    name: 'Име',
    chooseCurrency: 'Изаберите валуту',
    soloPrice: 'Соло цена - Година',
    soloPaddlePriceId: 'ID Соло цене paddle - Година',
    team5Price: 'Цена за тим 5 - Година',
    team5PaddlePriceId: 'ID paddle цене за тим 5 - Година',
    team10Price: 'Цена за тим 10 - Година',
    team10PaddlePriceId: 'ID paddle цене за тим 10 - Година',
    team25Price: 'Цена за тим 25 - Година',
    team25PaddlePriceId: 'ID paddle цене за тим 25 - Година',
    team50Price: 'Цена за тим 50 - Година',
    team50PaddlePriceId: 'ID paddle цене за тим 50 - Година',
    soloPriceMonthly: 'Соло цена - Месец',
    soloPaddlePriceIdMonthly: 'ID Соло цене paddle - Месец',
    team5PriceMonthly: 'Цена за тим 5 - Месец',
    team5PaddlePriceIdMonthly: 'ID paddle цене за тим 5 - Месец',
    team10PriceMonthly: 'Цена за тим 10 - Месец',
    team10PaddlePriceIdMonthly: 'ID paddle цене за тим 10 - Месец',
    team25PriceMonthly: 'Цена за тим 25 - Месец',
    team25PaddlePriceIdMonthly: 'ID paddle цене за тим 25 - Месец',
    team50PriceMonthly: 'Цена за тим 50 - Месец',
    team50PaddlePriceIdMonthly: 'ID paddle цене за тим 50 - Месец',
    chooseCountries: 'Изаберите земље',
    description: 'Опис',
    deletePricingRegion: 'Обриши цену региона',
    deleteMessage: 'Да ли сте сигурни да желите избрисати ову цену региона?',
  },
  notificationsHeaders: {
    time: 'Време',
    notification: 'Обавештење',
  },
  organisationsHeaders: {
    name: 'Организација',
    phoneNumber: 'Број телефона',
    email: 'Е-пошта',
    address: 'Адреса',
    companyId: 'ИД компаније',
    vat: 'ПДВ',
    active: 'Активно',
    createdAt: 'Регистровано',
    actions: 'Акције',
    billingDate: 'Датум наплате',
  },
  organisationsPage: {
    newItem: 'Нови унос организације',
    editItem: 'Измена организације',
    id: 'ИД',
    name: 'Организација',
    address: 'Адреса',
    phoneNumber: 'Број телефона',
    email: 'Е-пошта',
    vat: 'ПДВ',
    companyId: 'ИД компаније',
    warrantNumber: 'Број одлуке',
    image: 'Слика',
    signature: 'Потпис',
    seal: 'Печат',
    deactivateMessage: 'Да ли сте сигурни да желите да ',
    deactivate: 'ДЕАКТИВИРАТЕ',
    activate: 'АКТИВИРАТЕ',
    organisation: 'организацију',
    deleteOrganisation: 'Обришите организацију',
    deleteMessage: 'Да ли сте сигурни да желите да обришете ову организацију?',
    cancelSubscrMessage:
      'Да ли сте сигурни да желите отказати претплату? Имајте на уму да потврдом ове акције: 1. Налог ваше организације и сви повезани подаци биће трајно обрисани. Ова акција се не може поништити. 2. Бићете аутоматски одјављени из система одмах након отказивања. 3. Више нећете имати приступ за пријављивање или преузимање било каквих података везаних за вашу организацију у будућности. Препоручујемо да прегледате све потребне информације или извезете кључне податке пре наставка са овом неповратном акцијом. Ако имате питања или вам је потребна помоћ, не оклевајте да контактирате наш тим за подршку. Да ли желите наставити са отказивањем?',
    allDrivings: 'Укупан број налога за вожњу',
    allDrivingsAccepted: 'Укупан број прихваћених налога за вожњу',
    allDrivingsCanceled: 'Укупан број одбачених налога за вожњу',
    allVehicles: 'Укупан број возила',
    allUsers: 'Укупан број корисника',
    areYouSure: 'Да ли сте сигурни?',
    makeRoutesAvailable: 'Омогућите доступност интеграционих путева',
    percentage: 'Проценат',
    deleteImageMessage: 'Да ли сте сигурни да желите да избришете ову слику?',
    deleteImageTitle: 'Брисање слике',
    cancelSubscriptionTitle: 'Otkazivanje pretplate',
    subscriptionType: 'Тип претплате',
    billingDate: 'Датум наплате',
    articlesOfLaw: 'Чланови закона',
    country: 'Држава',
    referent: 'Референт',
    language: 'Језик',
    gnet: 'GNET',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'Пробни период завршава',
    languageTooltip:
      'Подразумевани језик за нове кориснике и језик на којем ће бити послати сви мејлови компаније (не индивидуални кориснички мејлови).',
    organisationType: 'Тип организације',
    regular: 'Редовни',
    affiliateSender: 'Аффилиат пошиљалац',
    affiliateReceiver: 'Аффилиат прималац',
    dispatcher: 'Диспечер',
  },
  transactionsHeaders: {
    invoiceNumber: 'Број фактуре',
    status: 'Статус',
    total: 'Укупно',
    currency: 'Валута',
    billedAt: 'Издато',
    actions: 'Акције',
  },
  transactionsPage: {
    newItem: 'Нова ставка трансакције',
    editItem: 'Уреди трансакцију',
    id: 'ИД',
    invoiceNumber: 'Број фактуре',
    status: 'Статус',
    sent: 'Послато',
    completed: 'Завршено',
    total: 'Укупно',
    currency: 'Валута',
    billedAt: 'Издато',
    actions: 'Акције',
    deleteTransaction: 'Обриши трансакцију',
    deleteMessage: 'Да ли сте сигурни да желите обрисати ову трансакцију?',
    currentSubscription: 'Тренутни пакет',
    nextBillingDate: 'Датум следећег наплаћивања',
    numberOfSeatsTaken: 'Број додатих чланова тима',
    numberOfSeatsAvailable: 'Број доступних чланова тима',
  },
  currenciesHeaders: {
    name: 'Назив',
    code: 'Код',
    symbol: 'Симбол',
    active: 'Активно',
    default: 'Основна',
    actions: 'Акције',
  },
  currenciesPage: {
    newItem: 'Нови унос валуте',
    editItem: 'Измена валуте',
    id: 'ИД',
    currency: 'Валута',
    code: 'Код',
    symbol: 'Симбол',
    deleteMessage: 'Да ли сте сигурни да желите да обришете ову валуту?',
  },
  paymentMethodsHeaders: {
    name: 'Назив',
    active: 'Активно',
    default: 'Основно',
    priceVisible: 'Цена видљива',
    actions: 'Акције',
  },
  paymentMethodsPage: {
    newItem: 'Нови унос начина плаћања',
    editItem: 'Измена начина плаћања',
    id: 'ИД',
    paymentMethod: 'Начин плаћања',
    deleteMessage: 'Да ли сте сигурни да желите да обришете овај начин плаћања?',
  },
  feedbacksHeaders: {
    subject: 'Назив',
    organisation: 'Организација',
    user: 'Корисник',
    actions: 'Акције',
  },
  passengersHeaders: {
    firstName: 'Име',
    lastName: 'Презиме',
    phoneNumber: 'Број телефона',
    email: 'Е-пошта',
    passport: 'Пасош',
    nationality: 'Националност',
    actions: 'Акције',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Локација путне тачке',
    arrivalTime: 'Време доласка',
    actions: 'Акције',
  },
  tables: {
    noDataText: 'Нема података',
    search: 'Претрага',
    drivings: 'Налози за вожњу',
    drivingsByPage: 'Налози за вожњу по страни',
    vehicles: 'Возила',
    vehiclesByPage: 'Возила по страни',
    vehiclesClasses: 'Класе возила',
    vehiclesClassesByPage: 'Класе возила по страни',
    expenses: 'Трошкови',
    expensesByPage: 'Трошковa по страни',
    vehiclesMaintenance: 'Кварови на возилу',
    vehiclesMaintenanceByPage: 'Кварови на возилу по страни',
    reportsByPage: 'Извештаји по страни',
    currencies: 'Валуте',
    currencieseByPage: 'Валуте по страни',
    payments: 'Начини плаћања',
    paymentsByPage: 'Начини плаћања по страни',
    saasAdministrators: 'Сви администратори',
    users: 'Сви корисници',
    usersByPage: 'Корисници по страни',
    clients: 'Сви клијенти',
    clientsByPage: 'Клијенти по страни',
    organisations: 'Све организације',
    organisationsByPage: 'Организације по страни',
    rebates: 'Рабати',
    rebatesByPage: 'Рабати по страни',
    cards: 'Картице',
    cardsByPage: 'Картице по страни',
    transactions: 'Трансакције',
    transactionsByPage: 'Трансакције по страни',
    pricingRegions: 'Ценовне регије',
    pricingRegionsByPage: 'Ценовне регије по страни',
    feedback: 'Оцене',
    feedbacksByPage: 'Оцене по страни',
    notifications: 'Обавештења',
    notificationsByPage: 'Обавештења по страни',
    all: 'Све',
    passengers: 'Путници',
    passengersByPage: 'Путници по страни',
    of: 'од',
    checkpoints: 'Путне тачке',
    checkpointsByPage: 'Путне тачке по страни',
    invoices: 'Рачуни',
    invoicesByPage: 'Рачуни по страници',
    drivingOffers: 'Понуде за вожњу',
    drivingOffersByPage: 'Понуде за вожњу по страници',
    smsMessages: 'СМС поруке',
    smsMessagesByPage: 'СМС поруке по страни',
    resourcesUsed: 'Искоришћени ресурси',
    resourcesUsedByPage: 'Ресурси по страници',
    inNext3Days: 'У наредна 3 дана',
    notConfirmedOrPaid: 'Није потврђено или плаћено',
    inNext5Days: 'У наредних 5 дана',
    latestReviews: 'Најновије рецензије',
    passengerReviews: 'Рецензије путника',
    driversByPage: 'Возачи по страници',
    reviewsByPage: 'Рецензије по страници',
    partners: 'Партнери',
    partnersByPage: 'Партнери по страници',
    sentRequests: 'Послати захтеви',
    receivedRequests: 'Примљени захтеви',
    requestsByPage: 'Захтеви по страници',
    pricingZones: 'Ценовне зоне',
    pricingZonesByPage: 'Ценовне зоне по страници',
    pricingZoneRelations: 'Повезивање ценовних зона',
    pricingZoneRelationsByPage: 'Повезивање ценовних зона по страници',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Број прихваћених налога за вожњу',
    numberOfRejectedDrivings: 'Број одбачених налога за вожњу по возачу',
    vehicleDrivingsNumber: 'Број налога за вожњу по возилу',
    vehicleProfit: 'Зарада по возилу',
    monthDrivingsNumber: 'Број налога за вожњу по месецу',
    driverProfit: 'Зарада по возачу',
  },
  reportsHeaders: {
    vehicle: 'Возило',
    expenses: 'Трошкови',
    profit: 'Добит',
    earnings: 'Зарада',
    daysWorking: 'Радни дани',
    usageIndex: 'Индекс коришћења',
    client: 'Клијент',
    numberOfDrivings: 'Број Вожњи',
    percentForClient: 'Цена за клијента',
    sumOfPrice: 'Износ',
  },
  packages: {
    active: 'Активно',
    starterTitle: 'Почетни',
    monthlyPackagesMessage: 'Месечна претплата по организацији.',
    starterText: 'Идеално за јединце или мале тимове који само требају основну функционалност.',
    starterListItemOne: 'Мобилне апликације',
    starterListItemTwo: 'Обавештења путем притиска',
    starterListItemThree: 'Приступ администрацији',
    starterListItemFour: 'Интеграција на вебсајт',
    standardTitle: 'Стандард',
    standardText: 'Савршено за организације са више од 5 возача.',
    standardListItemOne: 'Пакет ПОЧЕТНИ је укључен',
    standardListItemTwo: 'Нема ограничења за број возача',
    standardListItemThree: '99,9% време активности',
    standardListItemFour: 'Извештаји и Управљање возилима',
    premiumTitle: 'Премијум',
    premiumText: 'Савршено за сервисе лимузина који раде на прецизним подацима.',
    premiumListItemOne: 'Пакет СТАНДАРД је укључен',
    premiumListItemTwo: 'БИ и Прогноза трошкова',
    premiumListItemThree: 'Извоз података',
    premiumListItemFour: 'Недељни и Месечни Извештаји',
    warningOne: '* Приказане цене су месечна претплата по кориснику',
    warningTwo: '* Након преласка на нови пакет, није могуће прелазити на мањи пакет у следећих 6 месеци',
    warningThree: '* Корисници демо плана имају увид у функционалности које нуди премијум пакет',
    warningOneAnnualPackage: 'Треба вам више? Имате већу организацију? Имамо пакет за ВАС.',
    warningTwoAnnualPackage: 'и наш тим за продавницу ће вас контактирати ускоро.',
    packageChange: 'Промена пакета',
    changePackage: 'Да ли сте сигурни да желите прећи на',
    package: 'пакет',
    annualPricing: 'Годишње цене',
    monthlyPricing: 'Месечнe ценe',
    packageType10: 'Тим 10',
    packageType25: 'Тим 25',
    packageType50: 'Тим 50',
    'Team 10': 'Тим 10',
    'Team 25': 'Тим 25',
    'Team 50': 'Тим 50',
    annualPackagesMessage: 'Годишња претплата по организацији.',
    packageTypeSoloMainMessage: 'Samo 1 uporabniški račun.',
    packageType10MainMessage: 'До 10 корисничких налога.',
    packageType25MainMessage: 'До 25 корисничких налога.',
    packageType50MainMessage: 'До 50 корисничких налога.',
    allFeaturesIncluded: 'Sve funkcionalnosti uključene.',
    unlimitedRides: 'Neograničen broj vožnji i vozila.',
    trialPeriod: 'Uključen probni period.',
    onboardingFeeOptional: 'Naknada za instalaciju opcionalna.',
    noHiddenCosts: 'Bez skrivenih troškova.',
    freeSmsFlights10: 'Besplatno 15 SMS-ova i 50 provjera letova mjesečno.',
    freeSmsFlights25: 'Besplatno 35 SMS-ova i 100 provjera letova mjesečno.',
    freeSmsFlights50: 'Besplatno 50 SMS-ova i 200 provjera letova mjesečno.',
  },
  successPaymentPage: {
    message: 'Успешно сте извршили плаћање. Хвала Вам.',
  },
  demoExpiring: {
    notification: 'Обавештење',
    expiredSubscriptionMessage:
      'Обавештавамо Вас да је ваша претплата на ову платформу истекла. Како бисте наставили користити наше услуге, молимо Вас да обновите своју претплату.',
  },
  login: {
    username: 'Корисничко име',
    password: 'Лозинка',
    rememberMe: 'Запамти ме',
    login: 'Пријавите се',
    noAccount: 'Још немате налог? Можете се регистровати',
    forgetPassword: 'Заборавили сте лозинку? Можете је ресетовати',
    registerHere: 'овде',
    email: 'Е-пошта',
    enterEmail: 'Унесите вашу е-пошту',
    enterPassword: 'Унесите вашу нову лозинку',
    signInWithGoogle: 'Пријавите се путем Google налога',
  },
  registration: {
    register: 'Регистрација',
    name: 'Име организације',
    address: 'Адреса',
    phoneNumber: 'Број телефона',
    email: 'Имејл',
    companyId: 'Матични број фирме',
    vat: 'ПДВ',
    language: 'Језик',
    package: 'Пакет',
    accept: 'Прихватам',
    terms: 'услове коришћења',
    successfullyRegistered: 'Корисник је успешно регистрован. Добићете и-мејл са верификационим линком.',
    haveAccount: 'Већ имате налог? Можете се пријавити',
    loginHere: 'Овде',
    trialPeriodInfo:
      'Укључен је бесплатан пробни период од 14 дана. Наплата ће уследити након завршетка пробног периода.',
  },
  driving: {
    drivingType: 'Тип резервације',
    general: 'Опште информације',
    newDriving: 'Закажи трансфер',
    newDailyRent: 'Закажи дневни најам',
    areYouSure: 'Да ли сте сигурни?',
    vehicleIsUnavailable: 'Возило је заузето.',
    vehicleIsDamaged: 'Возило је оштећено.',
    sureAboutThisVehicle: 'Да ли сте сигурни да желите изабрати ово возило?',
    driverUnavaliable: 'Возач је заузет.',
    sureAboutThisDriver: 'Да ли сте сигурни да желите изабрати овог возача?',
    checkVehicleSeatAvailability: 'Ово возило не може примити олико путника.',
    cancelDriving: 'Да ли сте сигурни да желите отказати налог за вожњу?',
    finishDriving: 'Да ли сте сигурни да желите завршити налог за вожњу?',
    noShowDriving: 'Да ли сте сигурни да желите да означите вожњу као недолазак?',
    deleteDriving: 'Да ли сте сигурни да желите избрисати налог за вожњу?',
    newTransfer: 'Нови трансфер',
    driving: 'Налог за вожњу',
    comments: 'Коментари',
    id: 'ИД',
    locationFrom: 'Почетна тачка',
    locationTo: 'Дестинација',
    vehicleClass: 'Класа возила',
    vehicle: 'Возило',
    driver: 'Возач',
    pickupTime: 'Време поласка',
    clientConfirmed: 'Клијент потврдио',
    description: 'Опис',
    extraInfo: 'Додатне информације',
    drivingNotAccepted: 'Нису прихватили налог за вожњу:',
    currency: 'Валута',
    paymentMethod: 'Начини плаћања',
    price: 'Цена',
    paid: 'Плаћено',
    distance: 'Удаљеност (км)',
    distanceMi: 'Удаљеност (миља)',
    expectedTime: 'Очекивано време',
    waitingTime: 'Време чекања (сати)',
    EnterTheNumberOfHoursWaiting: 'Унесите број сати чекања',
    dropOffTime: 'Време доласка',
    comebackTime: 'Време повратка',
    acceptUntil: 'Прихвати до',
    drivingRequired: 'Да ли је налог за вожњу обавезан',
    comment: 'Коментар',
    attachments: 'Прилози',
    addFiles: '+ Додај фајлове',
    deleteFiles: 'Да ли сте сигурни да желите избрисати овај фајл?',
    passengers: 'Путници',
    addPassenger: '+ Додај путника',
    deletePassenger: 'Да ли сте сигурни да желите избрисати овог путника?',
    chooseClient: 'Изаберите клијента',
    costs: 'Трошкови',
    checkpoints: 'Путне тачке',
    addCheckpoint: 'Додај путну тачку',
    deleteCheckpoint: 'Да ли сте сигурни да желите да избришете ову путну тачку?',
    waitingBoardText: 'Текст на чекању на табли',
    flightNumber: 'Број лета',
    flightInfo: 'Информације о лету',
    createdBy: 'Налог за вожњу креиран <b>{time}</b> од стране <b>{user}</b>',
    client: 'Клијент',
    note: 'Напомена',
    noteDriver: 'Напомена за возача',
    noteDispatcher: 'Напомена за диспечера',
    passenger: 'Путник',
    fullName: 'Име и презиме',
    phoneNumber: 'Број телефона',
    email: 'Емаил',
    suitcasesNumber: 'Број кофера',
    passengersNumber: 'Број путника',
    babySeatsNumber: 'Број седишта за бебе',
    stopsNumber: 'Број заустављања',
    steps: {
      mainInfo: 'Основне информације',
      additionalInfo: 'Додатне информације',
      review: 'Преглед',
    },
    commissionAmount: 'Провизија',
    sendSms: 'Пошаљи SMS',
    message: 'Порука',
    acceptDriving: 'Да ли сте сигурни да желите прихватити вожњу?',
    rejectDriving: 'Да ли сте сигурни да желите одбити вожњу?',
    calculatePriceViaZonePricing: 'Израчунај цену путем ценовне зоне',
    cannotCalculatePriceViaZonePricingForDailyRents: 'Није могуће израчунати цену путем ценовне зоне за дневне ренте',
    disableCalculatePriceViaZonePricing:
      'Није могуће израчунати цену путем ценовне зоне без локације, валуте и класе возила',
    numberOfPassengers: 'Број путника',
    numberOfSuitcases: 'Број кофера',
    vatPercentage: 'Проценат ПДВ-а',
    priceType: 'Тип цене',
  },
  drivingStatus: {
    pending: 'На чекању',
    accepted: 'Прихваћено',
    rejected: 'Одбијено',
    expired: 'Истекло',
    canceled: 'Отказано',
    draft: 'Нацрт',
    done: 'Завршено',
    noShow: 'Недолазак',
  },
  drivingOrderType: {
    dailyRent: 'Дневни најам',
    transfer: 'Трансфер',
  },
  flightInfo: {
    airlineName: 'Назив авио компаније',
    flightNumber: 'Број лета',
    departureAirport: 'Аеродром поласка',
    departureCountry: 'Земља поласка',
    departureTime: 'Време поласка',
    arrivalAirport: 'Аеродром доласка',
    arrivalCountry: 'Земља доласка',
    arrivalTime: 'Време доласка',
    departureDelayed: 'Кашњење поласка',
    flightStatus: 'Статус лета',
    percentageOfFlight: 'Проценат лета',
  },
  customValidations: {
    fileType: `Тип фајла .{extension} није подржан`,
    fileSize: 'Фајлови не могу бити већи од {size}',
    fieldRequired: 'Поље {field} је обавезно',
    fieldType: 'Поље {field} мора бити типа {type}',
    fieldLengthMin: 'Поље {field} мора бити {length} или више',
    fieldLengthMax: 'Поље {field} мора бити {length} или мање',
    fieldUnique: 'Поље {field} мора бити јединствено',
    fieldValid: 'Поље {field} мора бити важеће',
    noActiveSubscription: 'Нема активне претплате',
    usersLimitExceeded: 'Превише корисника',
    fieldBefore: '{field1} мора бити пре {field2}',
    flightNotExist: 'Лет {field} не постоји',
    gnetKeyNotValid: 'Унесени Gnet кључ није валидан.',
    gnetPassengersRequired: 'Налог за вожњу не може бити послат GNET-у без путника.',
    priceAndCurrencyRequired: 'Линк за плаћање не може бити генерисан без цене и валуте',
    fieldValueNotSupported: 'Вредност поља {field} није подржана',
    noCountryCode: 'Нисте одабрали земљу',
    noStripeForYourCountry: 'Stripe није доступан у вашој земљи',
    stripeAlreadyEnabled: 'Stripe је већ активиран',
    fieldValueMin: 'Поље {field} мора бити {min} или више',
    fieldValueMax: 'Поље {field} мора бити {max} или мање',
    markAsDoneDate: 'Време поласка не може бити у будућности',
    phoneNumberNotValidForSms:
      'Број телефона није валидан за слање SMS-а. Важићи број телефона мора садржати позивни број државе.',
    affiliateIdDoesNotExist: 'Организација са датим affiliate ID-јем не постоји.',
    numericDecimal: 'Поље {field} може садржавати само нумеричке карактере (дозвољени децимали)',
  },
  fieldTypes: {
    string: 'стринг',
    integer: 'број',
    date: 'датум',
  },
  names: {
    username: 'корисничко име',
    password: 'лозинка',
    choose_client: 'изаберите клијента',
    choose_month: 'изаберите месец',
    currency: 'валута',
    payment_method: 'начин плаћања',
    vehicle_class: 'класа возила',
    first_name: 'име',
    last_name: 'презиме',
    passport: 'пасош',
    nationality: 'националност',
    plate_number: 'регистарска ознака',
    brand: 'марка автомобила',
    number_of_passengers: 'број путника',
    fuel_consumption: 'потрошња горива',
    year_of_manufacture: 'година производње',
    price_per_km: 'цена по км',
    price_per_hour: 'цена по сату',
    price_per_waiting_hour: 'цена по сату чекања',
    vehicle: 'возило',
    description: 'опис',
    time_from: 'време од',
    time_to: 'време до',
    name: 'име',
    cost_type: 'тип трошка',
    cost_time: 'време трошка',
    price: 'цена',
    rebate_min: 'минимални број наруџби за вожњу',
    rebate_max: 'максимални број наруџби за вожњу',
    rebate: 'попуст',
    phone_number: 'број телефона',
    organisation: 'организација',
    role: 'улога',
    image: 'слика',
    location_from: 'почетна локација',
    location_to: 'локација одредишта',
    pickup_time: 'време поласка',
    distance: 'удаљеност',
    waiting_time: 'време чекања',
    comment: 'коментар',
    address: 'адреса',
    vat: 'ПДВ',
    company_id: 'идентификациони број фирме',
    company_personal_id: 'пиб/лични id',
    licence: 'дозвола',
    warrant_number: 'број овлашћења',
    seal: 'печат',
    signature: 'потпис',
    email: 'имејл',
    percentage: 'проценат',
    organizationName: 'име организације',
    package: 'пакет',
    choose_currency: 'изаберите валуту',
    team_10_price: 'цена за Тим 10',
    team_10_paddle_price_id: 'ИД цене за Тим 10 Паддле',
    team_25_price: 'цена за Тим 25',
    team_25_paddle_price_id: 'ИД цене за Тим 25 Паддле',
    team_50_price: 'цена за Тим 50',
    team_50_paddle_price_id: 'ИД цене за Тим 50 Паддле',
    team_10_price_monthly: 'цена за Тим 10',
    team_10_paddle_price_id_monthly: 'ИД цене за Тим 10 Паддле',
    team_25_price_monthly: 'цена за Тим 25',
    team_25_paddle_price_id_monthly: 'ИД цене за Тим 25 Паддле',
    team_50_price_monthly: 'цена за Тим 50',
    team_50_paddle_price_id_monthly: 'ИД цене за Тим 50 Паддле',
    countries: 'изаберите земље',
    invoice_number: 'број фактуре',
    status: 'статус',
    total: 'укупно',
    billed_at: 'издато',
    articles_of_law: 'чланови закона',
    country: 'држава',
    referent: 'референт',
    language: 'језик',
    location: 'локација',
    arrival_time: 'време доласка',
    waiting_board_text: 'текст на чекању на табли',
    flight_number: 'број лета',
    gnet_id: 'GNET ID',
    receiver_id: 'ID примаоца',
    preferred_vehicle_type: 'жељени тип возила',
    reservation_type: 'тип резервације',
    run_type: 'тип вожње',
    trip_duration_minutes: 'трајање пута',
    client: 'клијент',
    client_type: 'тип клијента',
    card_holder_name: 'име власника картице',
    card_number: 'број картице',
    expiry_date: 'датум истека',
    cvv: 'cvv',
    code: 'код',
    symbol: 'симбол',
    quantity: 'количина',
    discount: 'попуст',
    driving_type: 'тип вожње',
    full_name: 'пуно име',
    suitcases_number: 'број кофера',
    passengers_number: 'број путника',
    baby_seats_number: 'број дечијих седишта',
    stops_number: 'број заустављања',
    commission_amount: 'провизија',
    message: 'порука',
    affiliate_id: 'affiliate id',
    coordinates: 'координате',
    zone_one: 'зона један',
    zone_two: 'зона два',
    price_type: 'тип цене',
  },
  weekDays: {
    monday: 'Понедељак',
    tuesday: 'Уторак',
    wednesday: 'Среда',
    thursday: 'Четвртак',
    friday: 'Петак',
    saturday: 'Субота',
    sunday: 'Недеља',
  },
  select: {
    noDataAvailable: 'Нема доступних података',
    selected: 'одабрано',
  },
  excelExport: {
    to_date_range: 'Извештај се не може генерисати за будуће датуме. Унесите исправне датуме',
    overlap_range: 'Време завршетка не може бити веће од времена почетка.',
  },
  setPassword: {
    success: 'Имејл послат',
    checkEmail: 'Проверите свој имејл за даље упутстве.',
    already_changed: 'Већ је промењено',
    send_new_reset_request: 'Већ сте променили лозинку са овим линком. Пошаљите нов захтев за ресетовање лозинке.',
  },
  statisticsPage: {
    reportType: 'Тип извештаја',
    reportTime: 'Време извештаја',
    vehicleUtilization: 'Искоришћеност возила',
    costEffectiveness: 'Ефикасност трошкова',
    generalReport: 'Општи извештај',
    clientsReport: 'Извештај по Клијентима',
  },
  profile: {
    yourProfile: 'Ваш профил',
    name: 'Име',
    role: 'Улога',
    username: 'Корисничко име',
    password: 'Лозинка',
    phoneNumber: 'Број телефона',
    email: 'Имејл',
    percentage: 'Проценат',
  },
  gpsMaps: {
    noTransfers: 'Нема текућих или предстојећих трансфера.',
    map: 'Карта',
    transfers: 'Налози',
    drivers: 'Возачи',
    ongoing: 'У току',
    upcoming: 'Надолазећи',
    driving: 'Вожња',
    driver: 'Возач',
    vehicle: 'Возило',
    requestDriverLocation: 'Захтев за локацију возача',
    lastRecordedLocation: 'Последња забележена локација',
  },
  driverStatus: {
    available: 'Доступан',
    offline: 'Офлајн',
    inTransfer: 'У трансферу',
  },
  onboardingTutorialMain: {
    languageStep: 'Овде можете поставити жељени језик за платформу.',
    notificationsStep: 'Овде можете пронаћи своја обавештења.',
    addTransferStep: 'Кликните ово дугме за креирање нове резервације, једносмерних трансфера и дневних ренти.',
    sideMenuButtonStep: 'Кликните ову икону да прикажете или сакријете мени.',
    sideMenuExpStep:
      'Одавде можете навигирати главним секцијама платформе: Резервације, Возни парк, Трошкови, Администрација, Фактуре, Статистика, итд.',
    sideMenuButtonCloseStep: 'Кликните исту икону да сакријете мени.',
    supportStep: 'Ако имате било каква питања или проблеме, слободно контактирајте корисничку подршку кликом овде.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Возила ће бити приказана у овој табели након што их додате.',
    searchStep: 'Можете лако пронаћи возила претрагом по марки, регистарској ознаци, години итд.',
    addStep: 'Додајте нова возила кликом на дугме „Додај“. Прво додајте све „Класе возила“ пре уноса возила.',
  },
  onboardingTutorialStatistics: {
    typeStep:
      'У овом делу можете пронаћи све статистичке податке приказане у графиконима. Овде одаберите тип статистике.',
    intervalStep: 'Изаберите интервал за који желите преглед статистике.',
    downloadStep: 'Такође можете преузети и одштампати ове табеле.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'У GPS модулу можете пратити своје возаче и трансфере на једном месту. Изаберите ову картицу за приказ актуелних и надолазећих трансфера (следећих 24ч).',
    tabTwoStep: 'Изаберите возила да их видите на мапи. Можете доделити доступна возила трансферима директно одавде.',
    mapStep: 'Изаберите или уклоните возила кликом на њихов пин на мапи.',
  },
  onboardingTutorialInvoices: {
    tableStep: 'Све фактуре ће бити приказане у овој табели, од најновијих до најстаријих.',
    searchStep: 'Лако пронађите фактуре претрагом по броју, износу, имену клијента, датуму креирања итд.',
    addStep: 'Креирајте нове фактуре кликом на дугме „Додај“.',
  },
  onboardingTutorialOffers: {
    tableStep: 'Понуде ће бити приказане у овој табели након што их додате, од најновијих до најстаријих.',
    searchStep: 'Лако пронађите понуде претрагом по броју, износу или опису.',
    addStep: 'Креирајте нове понуде кликом на дугме „Додај“.',
  },
  onboardingTutorialResources: {
    infoStep: 'Приказује се колико вам ресурса преостаје, а на захтев можете купити још SMS порука.',
    tableStep:
      'У овој табели можете пронаћи евиденцију и историју свих коришћених ресурса (послати SMS-ови и проверени летови).',
    searchStep: 'Лако пронађите ресурсе претрагом по садржају, кориснику или датуму креирања.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Рецензије путника ће бити приказане у овој табели, од најновијих до најстаријих.',
    searchStep: 'Лако пронађите рецензије путника претрагом по броју или коментару путника.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Зоне цена ће бити приказане у овој табели након што их додате.',
    searchStep: 'Лако пронађите зоне цена претрагом.',
    addStep: 'Креирајте нове зоне цена кликом на дугме „Додај“.',
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Релације зона цена (цене) ће бити приказане у овој табели након што их додате.',
    searchStep: 'Лако пронађите релације зона цена претрагом.',
    addStep:
      'Креирајте нове релације зона цена (цене) кликом на дугме „Додај“. Прво додајте „Зоне цена“ пре коришћења ове функције.',
  },
  onboardingTutorialDashboard: {
    infoStep: 'Приказује сажетак статистике ваше организације за последњих 30 дана.',
    next3DaysStep: 'Резервације за наредна 3 дана ће бити приказане у овој табели.',
    notPaidOrConfirmedStep:
      'Резервације за наредних 7 дана које нису потврђене или плаћене ће бити приказане у овој табели.',
    next5DaysStep: 'Заузети возачи за наредних 5 дана ће бити приказани у овој табели.',
    latestReviewsStep: 'Најновије рецензије вожњи ће бити приказане у овој табели.',
  },
  onboardingTutorialAffiliate: {
    tabStep: 'Променом картица можете видети своје партнере, послате захтеве или примљене захтеве.',
    idStep: 'Овде можете пронаћи свој affiliate ID који можете поделити са партнерима.',
    addStep: 'Креирајте нови захтев за affiliate партнера кликом на дугме „Додај“.',
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      'Кликом овде можете приступити Напредним подешавањима ваше организације и поставити све атрибуте и интеграције.',
  },
  advancedSettings: {
    advancedSettings: 'Напредна подешавања',
    distanceUnit: 'Јединица за удаљеност',
    distanceUnitDescription: 'Изаберите јединицу за мерење удаљености.',
    kilometers: 'Километри',
    miles: 'Миље',
    dateFormat: 'Формат датума',
    dateFormatDescription: 'Изаберите формат датума који желите да користите.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Обавезне вожње',
    mandatoryDrivingsDescription: 'Одаберите ако желите да све вожње буду обавезне по дефаулту.',
    mandatoryDrivingsDefault: 'Обавезне Вожње (Дефаулт)',
    paidDrivings: 'Плаћене вожње',
    paidDrivingsDescription: 'Одаберите ако желите да све вожње буду плаћене по дефаулту.',
    paidDrivingsDefault: 'Плаћене вожње (По дефаулту)',
    notificationsEmail: 'Е-маил за обавештења',
    notificationsEmailDescription:
      'Промените дефаултни е-маил за примање обавештења (ако не желите користити е-маил ваше организације).',
    email: 'Е-маил',
    articlesOfLaw: 'Чланови закона',
    integrations: 'Интеграције',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET је свеобухватна платформа дизајнирана за оптимизацију праћења у реалном времену, управљања флотом и диспечерских операција за транспортну индустрију.',
    makeRoutesAvailable: 'Омогућите доступност интеграционих путева',
    makeRoutesAvailableDescription:
      'Активирајте свој API кључ да интегришете LimoExpress са вашим веб сајтом, ERP системом и још много тога.',
    makePublicFormAvailable: 'Омогући јавни образац',
    makePublicFormAvailableDescription:
      'Активирајте ову опцију како бисте омогућили приступ јавној страници за резервацију, тако да ваши клијенти могу приступити напредном обрасцу за резервацију и лако креирати налоге. Линк можете користити у билтенима, на сајту, у флајерима; то је јединствен линк само за вашу компанију. Такође, можете добити део кода за ембедовање обрасца за резервацију директно на свом сајту.',
    emailsEnabled: 'Омогућена email обавештења',
    emailsEnabledDefault: 'Омогућена email обавештења',
    emailsEnabledDescription: 'Одаберите да бисте примали обавештења путем email-а.',
    passengerReviewsEnabled: 'Омогућене оцене путника',
    passengerReviewsEnabledDefault: 'Омогућене оцене путника',
    passengerReviewsEnabledDescription:
      'Активирањем ове функције ваши путници (уколико је унесен имејл) добиће имејл потврду након завршене вожње (означене као Завршено) и имаће могућност да дају оцене (повратне информације) за ваше услуге.',
    vat: 'ПДВ',
    vatPercentage: 'Проценат ПДВ-а',
    vatPercentageDescription: 'Унесите проценат ПДВ-а.',
    invoicePaymentInstructions: 'Упутства за плаћање фактуре',
    stripe: 'Stripe',
    stripeDescription:
      'Активирајте Stripe како би могли да наплаћујете своје од својих клијената. LimoExpress не узима провизију.',
    dataExport: 'Извоз података',
    dataExportDescription: 'Можете извозити све своје податке, и они ће вам бити послати на емаил.',
    sureAboutDataExport:
      'Да ли сте сигурни да желите да извозите све своје податке? Ако јесте, сви подаци ће вам стићи на емаил у наредна 2 сата.',
    defaultPriceType: 'Подразумевани тип цене',
    defaultPriceTypeDescription: 'Поставите подразумевани тип цене како би се одредио приказ цена.',
    NET: 'НЕТ',
    GROSS: 'БРУТО',
    link: 'Линк',
    code: 'Код',
    language: 'Језик',
    chooseSize: 'Изаберите величину',
    small: 'Мала',
    medium: 'Средња',
    large: 'Велика',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Број налога',
    drivingType: 'Тип резервације',
    drivingStatus: 'Статус',
    locationFrom: 'Полазна тачка',
    locationTo: 'Одредиште',
    driver: 'Возач',
    client: 'Клијент',
    plateNumber: 'Регистарски број',
    note: 'Опис',
    fuelConsumption: 'Потрошња горива',
    pickupTime: 'Време поласка',
    numberOfPassengers: 'Број путника',
    passengers: 'Путници',
    price: 'Цена',
    paid: 'Плаћено',
    paymentMethod: 'Метод плаћања',
    waitingTime: 'Време чекања (h)',
    distance: 'Удаљеност (km)',
    distanceMi: 'Удаљеност (миља)',
    selectAll: 'Изабери све',
  },
  gnet: {
    gnetInfo: 'GNET информације',
    requesterId: 'ID подносиоца захтева',
    receiverId: 'ID примаоца захтева',
    preferredVehicleType: 'Препоручени тип возила',
    reservationType: 'Тип резервације',
    runType: 'Тип вожње',
    price: 'Цена',
    currency: 'Валута',
    noteDriver: 'Напомена за возача',
    noteDispatcher: 'Напомена за диспечера',
    tripDurationMinutes: 'Трајање путовања (минути)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Број фактуре',
    client: 'Клијент',
    totalPrice: 'Укупна цена',
    drivings: 'Вожње',
    paid: 'Плаћено',
    currency: 'Валута',
    createdAt: 'Креирано',
    actions: 'Акције',
  },
  invoicesPage: {
    newItem: 'Нови унос фактуре',
    chooseClient: 'Изаберите клијента',
    chooseDrivings: 'Изаберите вожње',
    allDrivings: 'Све вожње',
    deleteMessage: 'Да ли сте сигурни да желите да обришете ову фактуру?',
    markInvoiceAsPaid: 'Да ли сте сигурни да желите да означите ову фактуру као плаћену?',
  },
  drivingOffersHeaders: {
    number: 'Број понуде за вожњу',
    totalPrice: 'Укупна цена',
    description: 'Опис',
    paid: 'Плаћено',
    currency: 'Валута',
    createdAt: 'Креирано',
    actions: 'Акције',
  },
  drivingOffersPage: {
    newItem: 'Нови унос понуде вожње',
    item: 'Ставка',
    description: 'Опис',
    title: 'Наслов',
    vehicleClass: 'Класа возила',
    quantity: 'Количина',
    price: 'Цена',
    discount: 'Попуст',
    vat: 'ПДВ',
    deleteMessage: 'Да ли сте сигурни да желите да избришете ову понуду за вожњу?',
    markDrivingOfferAsPaid: 'Да ли сте сигурни да желите да означите ову понуду за вожњу као плаћену?',
  },
  modal: {
    reservationSuccessTitle: 'Успех',
    reservationSuccessMessage: 'Захтев за резервацију је успешно послат. Неко ће вас ускоро контактирати.',
    reservationErrorTitle: 'Форма онемогућена',
    reservationErrorMessage:
      'Форма је тренутно онемогућена од стране организације. Молимо контактирајте подршку или покушајте поново касније.',
  },
  dashboardPage: {
    drivingCountToday: 'Број вожњи (данас)',
    drivingCountThisMonth: 'Број вожњи (последњих 30 дана)',
    dailyRentsAndTransfers: 'Број вожњи (дневни најам/трансфери)',
    drivingTotalAmount: 'Укупна сума',
    driversCount: 'Активни возачи (±7 дана)',
    passengerCount: 'Број путника (последњих 30 дана)',
  },
  reviewsHeaders: {
    rating: 'Оцена',
    comment: 'Коментар',
    createdAt: 'Креирано',
  },
  resourceOverviewHeaders: {
    actionType: 'Тип акције',
    user: 'Корисник',
    action: 'Акција',
    createdAt: 'Креирано',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Број преосталих СМС порука',
    flightsCheckLeft: 'Број преосталих провера летова',
    sms: 'СМС',
    flightCheck: 'Провера лета',
  },
  affiliateHeaders: {
    organisation: 'Организација',
    createdAt: 'Креирано',
    actions: 'Акције',
  },
  affiliatePage: {
    partners: 'Партнери',
    sentRequests: 'Послати захтеви',
    receivedRequests: 'Примљени захтеви',
    acceptRequest: 'Да ли сте сигурни да желите прихватити овај захтев?',
    rejectRequest: 'Да ли сте сигурни да желите одбити овај захтев?',
    deletePartner: 'Да ли сте сигурни да желите избрисати овог партнера?',
    deleteRequest: 'Да ли сте сигурни да желите избрисати захтев?',
    newItem: 'Нови захтев',
    affiliateId: 'Affiliate ID',
  },
  affiliate: {
    affiliateInfo: 'Affiliate информације',
    affiliate: 'Affiliate',
    partner: 'Партнер',
    preferredVehicleType: 'Преферирани тип возила',
    driverInfo: 'Информације о возачу',
    vehicleInfo: 'Информације о возилу',
    price: 'Цена',
    currency: 'Валута',
  },
  pricingZonesHeaders: {
    name: 'Име',
    code: 'Шифра',
    actions: 'Акције',
  },
  pricingZonesPage: {
    newItem: 'Нови унос ценовне зоне',
    editItem: 'Измена ценовне зоне',
    id: 'ИД',
    name: 'Име',
    code: 'Шифра',
    deleteMessage: 'Да ли сте сигурни да желите да обришете ову ценовну зону?',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Зона један',
    zoneTwo: 'Зона два',
    actions: 'Акције',
  },
  pricingZoneRelationsPage: {
    newItem: 'Унос нове везе ценовних зона',
    editItem: 'Измена везе ценовних зона',
    zoneOne: 'Зона један',
    zoneTwo: 'Зона два',
    deleteMessage: 'Да ли сте сигурни да желите да обришете ову везу ценовних зона?',
  },
};
