<template>
  <div v-if="this.$store.state.auth.role !== 1">
    <v-tooltip left>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn class="navbar-icon px-2 px-sm-4" text v-on="{ ...tooltip }" @click="resetTour">
          <v-icon id="v-step-1"> mdi-help-circle </v-icon>
        </v-btn>
      </template>
      <span>
        {{ $t('header.help') }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  //   name: 'Reset Tour',
  data() {
    return {};
  },
  methods: {
    // Function to reset the tour based on current route
    resetTour() {
      switch (this.$route.name) {
        case 'Vehicles':
          this.deleteVisitedStepsByComponentId(2);
          break;
        case 'Statistics':
          this.deleteVisitedStepsByComponentId(3);
          break;
        case 'GPSMap':
          this.deleteVisitedStepsByComponentId(4);
          break;
        case 'Invoices':
          this.deleteVisitedStepsByComponentId(5);
          break;
        case 'DrivingOffers':
          this.deleteVisitedStepsByComponentId(6);
          break;
        case 'ResourceOverview':
          this.deleteVisitedStepsByComponentId(7);
          break;
        case 'PassengerReviews':
          this.deleteVisitedStepsByComponentId(8);
          break;
        case 'PricingZonesPage':
          this.deleteVisitedStepsByComponentId(9);
          break;
        case 'PricingZoneRelationsPage':
          this.deleteVisitedStepsByComponentId(10);
          break;
        case 'UsersOrganisation':
          this.deleteVisitedStepsByComponentId(11);
          break;
        case 'Dashboard':
          this.deleteVisitedStepsByComponentId(12);
          break;
        case 'AffiliatePage':
          this.deleteVisitedStepsByComponentId(13);
          break;
        default:
          break;
      }
    },

    deleteVisitedStepsByComponentId(componentId) {
      let user = this.$store.getters['auth/user'];

      if (user && user.user_tour_logs) {
        // Filter out steps with the specified component_id
        user.user_tour_logs = user.user_tour_logs.filter((step) => parseInt(step.component_id) !== componentId);

        // Update localStorage with the filtered steps
        this.$store.dispatch('auth/setUser', user);
        localStorage.setItem('LimoExpressUser', JSON.stringify(user));
      }

      // Dispatch Vuex action to signal tour reset
      this.$store.dispatch('onboardingTutorial/resetTour');
    },
  },
};
</script>
