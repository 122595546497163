<template>
  <v-container>
    <validation-observer ref="affiliateForm" v-slot="{ handleSubmit, failed }">
      <v-form lazy-validation @submit.prevent="handleSubmit(sendDrivingToAffiliate)">
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="12">
            <validation-provider rules="required|max:255" v-slot="{ errors }" name="partner">
              <v-autocomplete
                v-model="affiliateData.organisation_id"
                :items="partners"
                clearable
                item-text="affiliated_organisation.name"
                item-value="affiliated_organisation.id"
                :label="$t('affiliate.partner')"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"
                :error-messages="errors"></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <validation-provider rules="required|max:255" v-slot="{ errors }" name="preferred_vehicle_type">
              <v-autocomplete
                v-model="affiliateData.desired_vehicle_class"
                :items="preferredVehicleTypes"
                clearable
                :label="$t('affiliate.preferredVehicleType')"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"
                :error-messages="errors"
                item-value="text"
                item-text="text">
                <template v-slot:item="data">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="d-flex align-center" style="width: 100%">
                        {{ data.item.text }}
                      </div>
                    </template>
                    <span>{{ `${data.item.text} - ${data.item.tooltip}` }}</span>
                    <!-- Customize this as needed -->
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <validation-provider rules="required|numeric_decimal|min_value:0|max:255" v-slot="{ errors }" name="price">
              <v-text-field
                v-model="affiliateData.price"
                clearable
                :label="$t('affiliate.price')"
                type="text"
                :error-messages="errors"></v-text-field
            ></validation-provider>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <validation-provider rules="required|max:255" v-slot="{ errors }" name="currency">
              <v-autocomplete
                v-model="affiliateData.currency_id"
                :items="currencies"
                clearable
                item-text="name"
                item-value="id"
                :label="$t('affiliate.currency')"
                :error-messages="errors"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <validation-provider rules="max:1000" v-slot="{ errors }" name="description">
              <v-textarea
                v-model="affiliateData.note"
                counter="1000"
                :label="$t('driving.note')"
                rows="2"
                auto-grow
                clearable
                :error-messages="errors"></v-textarea
            ></validation-provider>
          </v-col>

          <v-col cols="12" lg="12" md="12" sm="12" class="d-flex justify-end">
            <button-submit :failed="loading || failed" :loading="loading" buttonText="buttons.send"></button-submit>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'AffiliateInfo',
  components: { ButtonSubmit },
  props: ['editedDrivingItem'],
  data: () => ({
    loading: false,
    affiliateData: {
      organisation_id: '',
      desired_vehicle_class: '',
      price: '',
      currency_id: '',
      note: '',
    },
    partners: [],
    currencies: [],
    preferredVehicleTypes: [
      { text: 'SEDAN LUX', tooltip: 'Seats: 2 - Sedan (Luxury)' },
      { text: 'SEDAN CORP', tooltip: 'Seats: 2 - Sedan (Corporate)' },
      { text: 'SEDAN', tooltip: 'Seats: 2 - Sedan (Luxury)' },
      { text: 'SEDAN HYBRID', tooltip: 'Seats: 2 - Sedan (Hybrid)' },
      { text: 'SUV LUX', tooltip: 'Seats: 3 - SUV (Luxury)' },
      { text: 'SUV', tooltip: 'Seats: 3 - SUV (Standard)' },
      { text: 'SUV CORP', tooltip: 'Seats: 3 - SUV (Corporate)' },
      { text: 'SUV SPECIALTY', tooltip: 'Seats: 6 - SUV (Specialty) i.e. Hummer' },
      { text: 'SPECIALTY', tooltip: 'Seats: 6 - Specialty Vehicle i.e. Classic Rolls Royce' },
      { text: 'LIMO', tooltip: 'Seats: 1-10 - Limousine' },
      { text: 'VAN MINI', tooltip: 'Seats: 8 - Mini Van (1-8 passengers)' },
      { text: 'VAN CARGO', tooltip: 'Seats: 10 - Van (Cargo/Luggage)' },
      { text: 'VAN CORP', tooltip: 'Seats: 10 - Van (Corporate)' },
      { text: 'SPRINTER', tooltip: 'Seats: 14 - SPRINTER' },
      { text: 'BUS MINI', tooltip: 'Seats: 25 - Mini-Bus (20-35 passengers)' },
      { text: 'BUS', tooltip: 'Seats: 1-35 - Bus (26-31 passengers)' },
      { text: 'COACH', tooltip: 'Seats: 1-60 - Motor Coach' },
      { text: 'GREETER', tooltip: 'Seats: 1-100 - Greeter' },
    ],
  }),
  mounted() {},
  created() {
    this.loadAffiliatePartners();
    this.loadAllCurrencies();
  },
  computed: {},
  methods: {
    async loadAffiliatePartners() {
      await this.$store.dispatch('affiliate/getAffiliatePartners').then((res) => {
        this.partners = res.data.map((item) => {
          return item;
        });
      });
    },

    async loadAllCurrencies() {
      await this.$store.dispatch('currencies/getOrganisationCurrencies').then((res) => {
        this.currencies = res.data.map((item) => {
          if (item.currency.active == '1') {
            return item.currency;
          }
        });
      });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('driving_id', this.editedDrivingItem?.id);
      formData.append('organisation_id', this.affiliateData.organisation_id ? this.affiliateData.organisation_id : '');
      // formData.append(
      //   'desired_vehicle_class',
      //   this.affiliateData.desired_vehicle_class ? this.affiliateData.desired_vehicle_class : ''
      // );
      formData.append('desired_vehicle_class', 'limo');
      formData.append('price', this.affiliateData.price ? this.affiliateData.price : '');
      formData.append('currency_id', this.affiliateData.currency_id ? this.affiliateData.currency_id : '');
      formData.append('note', this.affiliateData.note ? this.affiliateData.note : '');

      return formData;
    },

    async sendDrivingToAffiliate() {
      this.loading = true;

      let formData = this.createFormData();

      await this.$store
        .dispatch('affiliate/sendDrivingToAffiliate', formData)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.$emit('closeAffiliateModal');
          this.$emit('closeDrivingModal');
          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch((error) => {
          this.$store
            .dispatch('errorMessages/errorMapper', error.response.data.message)
            .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {},
};
</script>
