<template>
  <v-container class="pa-7">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <div class="d-flex flex-column flex-grow-1">
          <div
            v-for="(file, index) in attachments"
            :key="'attachment' + index"
            class="d-flex align-center justify-space-between w-100 my-1">
            <span class="file-name" @click="openFile(file)">{{ file?.file_name }}</span>
            <v-icon color="red darken-3" small depressed @click="deleteFile(file, index)" :disabled="disable">
              mdi-delete
            </v-icon>
          </div>
        </div>

        <v-file-input
          ref="addFilesRef"
          multiple
          truncate-length="0"
          chips
          v-model="editedItem.newFiles"
          label="Dodaj fajlove (.pdf,.doc, .jpg,.png)"
          @change="selectFiles"
          class="add-files d-none"></v-file-input>

        <v-btn @click="addFiles" :disabled="disable" class="primary cancel my-1">
          {{ $t('driving.addFiles') }}
        </v-btn>
        <span class="pl-2"></span>
        (.docx,.xlsx,.xls,.pdf,.jpg,.jpeg,.png)

        <div class="d-flex flex-column flex-grow-1">
          <div
            v-for="(file, index) in filesToSend"
            :key="'file' + index"
            class="d-flex align-center justify-space-between w-100 my-1">
            <span class="file-name" @click="openFile(file)">{{ file.name }}</span>
            <v-icon color="red darken-3" small depressed @click="deleteFile(file, index)" :disabled="disable">
              mdi-delete
            </v-icon>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="fileDialogDelete" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ fileToDelete?.file_name }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="fileDialogDelete = false"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('driving.deleteFiles') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary" text @click="deleteFileItemConfirm">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';

export default {
  name: 'AddFiles',
  props: ['editedItem', 'attachments', 'filesToSend', 'disable', 'deleteRoute'],
  data: () => ({
    fileDialogDelete: false,
    fileToDelete: {},
    indexOfFileToDelete: null,
  }),
  methods: {
    deleteFile(file, index) {
      this.fileToDelete = file;
      this.indexOfFileToDelete = index;
      this.fileDialogDelete = true;
    },
    async deleteFileItemConfirm() {
      if (this.fileToDelete?.url) {
        await this.$store
          .dispatch(this.deleteRoute, this.fileToDelete)
          .then(() => {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.fileDialogDelete = false;
            this.$emit('deleteOldFile', this.indexOfFileToDelete);
          })
          .catch(() => {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          });
      } else {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
        this.fileDialogDelete = false;
        this.$emit('deleteNewFile', this.indexOfFileToDelete);
      }
    },
    addFiles() {
      this.$refs.addFilesRef.$refs.input.click();
    },
    selectFiles() {
      this.editedItem.newFiles.map((item) => {
        let extension = item.name.substring(item.name.lastIndexOf('.') + 1);
        if (!['png', 'docx', 'xlsx', 'xls', 'pdf', 'jpg', 'jpeg'].includes(extension)) {
          this.$store.dispatch('showSnackbar', {
            text: i18n.t('customValidations.fileType', { extension }),
            color: 'red',
          });
        } else if (item.size > 5 * 1024 * 1024) {
          this.$store.dispatch('showSnackbar', {
            text: i18n.t('customValidations.fileSize', { size: '5MB' }),
            color: 'red',
          });
        } else {
          this.$emit('addNewFile', item);
        }
      });
    },
    openFile(file) {
      if (file?.url) {
        if (!file?.url.includes('https://') && !file?.url.includes('http://')) {
          window.open((process.env.VUE_APP_BACKEND_URL + file?.url).replace('public', 'storage'), '_blank');
        } else {
          window.open(file?.url, '_blank');
        }
      } else {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      }
    },
  },
};
</script>

<style>
.file-name {
  cursor: pointer;
}
</style>
