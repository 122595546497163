<template>
  <div>
    <v-bottom-navigation class="affiliate-banner yellow black--text d-flex align-center" fixed padless>
      <p class="mb-0">
        <span>
          {{ $t('footer.affiliateUser') }}
        </span>
        <strong>
          <a @click="openPaddleCheckoutModal" class="black--text text-decoration-none cursor-pointer">
            {{ $t('footer.here') }}
          </a>
        </strong>
      </p>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { initializePaddle } from '@paddle/paddle-js';
import { checkoutSuccessCallBack } from '@/mixins/paddle-functions';

export default {
  name: 'DemoExpiring',
  data: () => ({}),
  created() {
    let config = {
      token: process.env.VUE_APP_PADDLE_CLIENT_SIDE_TOKEN,
    };

    if (process.env.VUE_APP_ENV !== 'production') {
      config.environment = 'sandbox';
    }

    initializePaddle({
      ...config,
      eventCallback: (data) => {
        checkoutSuccessCallBack(data, this.$store);
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        this.paddle = paddleInstance;
      }
    });
  },
  methods: {
    async openPaddleCheckoutModal() {
      this.organisationEmail = this.$store.state.auth.user.organisation.email;
      if (this.paddle) {
        try {
          const { product } = await this.$store.dispatch('subscriptionPackages/getCalculatedPrice');
          this.paddle.Checkout.open({
            settings: {
              displayMode: 'overlay',
              theme: 'light',
              allowLogout: false,
            },
            items: [
              {
                priceId: product.price_id,
              },
            ],
            customer: {
              email: this.organisationEmail,
            },
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.affiliate-banner {
  bottom: 56px !important;

  a {
    text-decoration: none;
    color: inherit;
  }
}
</style>
