import {
  login,
  logout,
  customerLogout,
  socialLogin,
  saveUser,
  activeSubscription,
  verifyUserAndSetPassword,
  registerUser,
} from '@/api/auth';

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user_token: null,
    user: null,
    role: null,
    subscription: null,
    subscription_type_id: null,
    customer: null,
    trialBannerDisplay: false,
    chargeDateBody: null,
    paddleState: false,
    organisationType: 'regular',
  },
  mutations: {
    SET_AUTH_TOKEN(state, token) {
      state.user_token = token;
    },
    REMOVE_AUTH_TOKEN(state) {
      state.user_token = null;
    },
    REMOVE_ROLE(state) {
      state.role = null;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ROLE(state, user) {
      state.role = user.role_id;
    },
    REMOVE_USER(state) {
      state.user = null;
    },
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_SUBSCRIPTION(state, value) {
      state.subscription = value;
    },
    REMOVE_SUBSCRIPTION(state) {
      state.subscription = null;
    },
    SET_SUBSCRIPTION_TYPE_ID(state, value) {
      state.subscription_type_id = value;
    },
    REMOVE_SUBSCRIPTION_TYPE_ID(state) {
      state.subscription_type_id = null;
    },
    SET_CUSTOMER(state, customer) {
      state.customer = customer;
    },
    REMOVE_CUSTOMER(state) {
      state.customer = null;
    },
    SET_TRIAL_BANNER_DISPLAY(state, value) {
      state.trialBannerDisplay = value;
    },
    SET_CHARGE_DATE_BODY(state, value) {
      state.chargeDateBody = value;
    },
    SET_PADDLE_STATE(state, value) {
      state.paddleState = value;
    },
    SET_ORGANISATION_TYPE(state, value) {
      state.organisationType = value;
    },
  },
  actions: {
    login(state, data) {
      return login(data);
    },
    logout(data) {
      return logout(data);
    },
    customerLogout(data) {
      return customerLogout(data);
    },
    socialLogin(state, data) {
      return socialLogin(data);
    },
    registerUser(state, data) {
      return registerUser(data);
    },
    verifyUserAndSetPassword(state, data) {
      return verifyUserAndSetPassword(data);
    },
    setAuthToken({ commit }, newValue) {
      commit('SET_AUTH_TOKEN', newValue);
      commit('SET_AUTHENTICATED', true);
    },
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setRole({ commit }, user) {
      commit('SET_ROLE', user);
    },
    updateUser({ commit }, user) {
      const data = saveUser(user);
      commit('SET_USER', user);
      return data;
    },
    setAuthenticated({ commit }, value) {
      commit('SET_AUTHENTICATED', value);
    },
    setSubscription({ commit }, value) {
      commit('SET_SUBSCRIPTION', value);
    },
    setSubscriptionTypeId({ commit }, value) {
      commit('SET_SUBSCRIPTION_TYPE_ID', value);
    },
    setCustomer({ commit }, customer) {
      commit('SET_CUSTOMER', customer);
    },
    activeSubscription() {
      return activeSubscription();
    },
    removeAuthToken({ commit }) {
      commit('REMOVE_AUTH_TOKEN');
    },
    removeUser({ commit }) {
      commit('REMOVE_USER');
    },
    removeCustomer({ commit }) {
      commit('REMOVE_CUSTOMER');
    },
    removeUserData({ commit }) {
      commit('REMOVE_AUTH_TOKEN');
      commit('REMOVE_USER');
      commit('SET_AUTHENTICATED', false);
      commit('REMOVE_SUBSCRIPTION');
      commit('SET_ORGANISATION_TYPE', '');
      localStorage.setItem('LimoExpressToken', '');
      localStorage.setItem('LimoExpressUser', '');
      localStorage.setItem('loggedIn', '');
      localStorage.setItem('saveToken', '');
      localStorage.setItem('subscription', '');
      localStorage.setItem('subscription_type_id', '');
      localStorage.setItem('customer', '');
      localStorage.setItem('organisation_type', 'regular');
      localStorage.setItem('availabilityData', '');
    },
    setTrialBannerDisplay({ commit }, value) {
      commit('SET_TRIAL_BANNER_DISPLAY', value);
    },
    setChargeDateBody({ commit }, value) {
      commit('SET_CHARGE_DATE_BODY', value);
    },
    setPaddleState({ commit }, value) {
      commit('SET_PADDLE_STATE', value);
    },
    setOrganisationType({ commit }, value) {
      commit('SET_ORGANISATION_TYPE', value);
    },
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    authToken(state) {
      return state.user_token;
    },
    user(state) {
      return state.user;
    },
    role(state) {
      return state.role;
    },
    subscription(state) {
      return state.subscription;
    },
    subscriptionTypeId(state) {
      return state.subscription_type_id;
    },
    chargeDateBody(state) {
      return state.chargeDateBody;
    },
    organisationType(state) {
      return state.organisationType;
    },
  },
};
