import { getHttpClient } from './client';

export const getAffiliatePartners = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/affiliate/accepted-affiliates';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getAffiliateSentRequests = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/affiliate/pending-requested-affiliates';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getAffiliateReceivedRequests = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/affiliate/pending-received-affiliates';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const saveAffiliate = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/affiliate/organisation-affiliate', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteAffiliate = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/affiliate/organisation-affiliate/' + data.id,
    'DELETE',
    data
  ).then((response) => response.data);
};

export const changeAffiliateRequestStatus = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/affiliate/change-affiliate-status', 'POST', data).then(
    (response) => response.data
  );
};

export const sendDrivingToAffiliate = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/affiliate/clone-driving-and-proposal', 'POST', data).then(
    (response) => response.data
  );
};

export const affiliateDrivingDecision = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/affiliate/status-decision', 'POST', data).then(
    (response) => response.data
  );
};