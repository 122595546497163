import { getHttpClient } from './client';

export const getAllDrivings = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/availability-data';
  let queryString = '?';
  if (data) {
    if (data.from) {
      queryString += `from=${data.from}`;
    }
    if (data.to) {
      queryString += `&to=${data.to}`;
    }
    if (data.vehicle_ids && Array.isArray(data.vehicle_ids)) {
      const vehicleIdsParam = data.vehicle_ids.join(',');
      queryString += `&vehicle_ids=[${vehicleIdsParam}]`;
    }
    if (data.driver_ids && Array.isArray(data.driver_ids)) {
      const driverIdsParam = data.driver_ids.join(',');
      queryString += `&user_ids=[${driverIdsParam}]`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};
