import {
  getAffiliatePartners,
  getAffiliateSentRequests,
  getAffiliateReceivedRequests,
  saveAffiliate,
  deleteAffiliate,
  changeAffiliateRequestStatus,
  sendDrivingToAffiliate,
  affiliateDrivingDecision,
} from '@/api/affiliate';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAffiliatePartners(state, item) {
      return getAffiliatePartners(item);
    },
    async getAffiliateSentRequests(state, item) {
      return getAffiliateSentRequests(item);
    },
    async getAffiliateReceivedRequests(state, item) {
      return getAffiliateReceivedRequests(item);
    },
    async saveAffiliate(state, item) {
      return saveAffiliate(item);
    },
    async deleteAffiliate(state, item) {
      return deleteAffiliate(item);
    },
    async changeAffiliateRequestStatus(state, item) {
      return changeAffiliateRequestStatus(item);
    },
    async sendDrivingToAffiliate(state, item) {
      return sendDrivingToAffiliate(item);
    },
    async affiliateDrivingDecision(state, item) {
      return affiliateDrivingDecision(item);
    },
  },
  getters: {},
};
