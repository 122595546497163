import { getAllReviews } from '@/api/passengerReviews';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllReviews(state, item) {
      return getAllReviews(item);
    },
  },
  getters: {},
};
