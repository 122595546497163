<template>
  <div>
    <v-dialog v-model="vehicleAvailabilityDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>{{ $t(vehicleAvailabilityDialogTitle) }} </v-toolbar>
        <v-card-text>
          <br />
          {{ $t('driving.sureAboutThisVehicle') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary cancel" text @click="clearOutVehicle">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="vehicleAvailabilityDialog = false">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="vehicleSeatAvailabilityDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>
          {{ $t('driving.checkVehicleSeatAvailability') }}
        </v-toolbar>
        <v-card-text>
          <br />
          {{ $t('driving.sureAboutThisVehicle') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary cancel" text @click="clearOutVehicle">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="vehicleSeatAvailabilityDialog = false">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="driverAvailabilityDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>
          {{ $t('driving.driverUnavaliable') }}
        </v-toolbar>
        <v-card-text>
          <br />
          {{ $t('driving.sureAboutThisDriver') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary cancel" text @click="clearOutDriver">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="driverAvailabilityDialog = false">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cancelDrivingDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>
          {{ $t('driving.areYouSure') }}
        </v-toolbar>
        <v-card-text>
          <br />
          {{ $t('driving.cancelDriving') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary cancel" text @click="cancelDrivingDialog = false">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="cancelDriving" :disabled="loadingCancel" :loading="loadingCancel">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="finishDrivingDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>
          {{ $t('driving.areYouSure') }}
        </v-toolbar>
        <v-card-text>
          <br />
          <p>{{ $t('driving.EnterTheNumberOfHoursWaiting') }}</p>
          <validation-provider rules="numeric|min_value:0" v-slot="{ errors }" name="\">
            <v-text-field
              v-model="editedDrivingItem.num_of_waiting_hours"
              clearable
              :label="$t('driving.waitingTime')"
              type="text"
              :error-messages="errors"></v-text-field>
          </validation-provider>
          <p>{{ $t('driving.finishDriving') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary cancel" text @click="finishDrivingDialog = false">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="finishDriving" :disabled="loadingFinish" :loading="loadingFinish">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="noShowDrivingDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>
          {{ $t('driving.areYouSure') }}
        </v-toolbar>
        <v-card-text>
          <br />
          <p>{{ $t('driving.noShowDriving') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary cancel" text @click="noShowDrivingDialog = false">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="noShowDriving" :disabled="loadingNoShow" :loading="loadingNoShow">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDrivingDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>
          {{ $t('driving.areYouSure') }}
        </v-toolbar>
        <v-card-text>
          <br />
          {{ $t('driving.deleteDriving') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary cancel" text @click="deleteDrivingDialog = false">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="deleteDriving" :disabled="loadingDelete" :loading="loadingDelete">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectedOpen"
      :activator="selectedElement"
      :close-on-content-click="false"
      max-width="800px"
      offset-x
      :key="selectedOpen">
      <v-card color="white lighten-4" flat min-width="350px">
        <div class="sticky-header">
          <v-toolbar class="primary" dark>
            <v-icon
              v-if="editedDrivingItem.id"
              :color="gnetDrivingStatusRejected || affiliateDrivingStatusRejected ? '#F46D6D' : editedDrivingItem.color"
              class="me-2 status-circle"
              >mdi-circle</v-icon
            >
            <v-toolbar-title
              >{{ editedDrivingItem.driving_number || $t(formTitle) }}
              <v-icon v-if="editedDrivingItem.id && editedDrivingItem.paid" size="29px"> mdi-cash-check </v-icon>
              {{ editedDrivingItem.id ? '-' : '' }}
              {{
                editedDrivingItem.id && (gnetDrivingStatusRejected || affiliateDrivingStatusRejected)
                  ? $t('drivingStatus.rejected')
                  : getStatus()
              }}
            </v-toolbar-title>

            <v-img
              v-if="editedDrivingItem.gnetData"
              alt="GNET"
              class="shrink ml-3"
              contain
              src="@/assets/gnet.png"
              transition="scale-transition"
              height="24px"
              width="60px" />

            <v-img
              v-if="editedDrivingItem?.affiliate?.affiliate_data_record"
              alt="Affiliate"
              class="shrink ml-3"
              contain
              src="@/assets/Logo mark.svg"
              transition="scale-transition"
              height="24px"
              width="24px" />

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-if="
                    (editedDrivingItem.gnetData && editedDrivingItem.gnet_reservation_creator == 0) ||
                    (editedDrivingItem?.affiliate?.affiliate_data_record &&
                      editedDrivingItem?.affiliate?.parent_driving_id)
                  "
                  alt="Export"
                  class="shrink ml-3"
                  contain
                  src="@/assets/file-import.svg"
                  transition="scale-transition"
                  style="filter: brightness(0) invert(1)"
                  height="24px"
                  width="24px"
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <span v-if="editedDrivingItem.gnetData && editedDrivingItem.gnet_reservation_creator == 0">
                {{ $t('tooltips.receivedViaGnet') }}
              </span>
              <span
                v-if="
                  editedDrivingItem?.affiliate?.affiliate_data_record && editedDrivingItem?.affiliate?.parent_driving_id
                ">
                {{ $t('tooltips.receivedViaAffiliate') }}
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-if="
                    (editedDrivingItem.gnetData && editedDrivingItem.gnet_reservation_creator == 1) ||
                    (editedDrivingItem?.affiliate?.affiliate_data_record &&
                      editedDrivingItem?.affiliate?.child_driving_id)
                  "
                  alt="Import"
                  class="shrink ml-3"
                  contain
                  src="@/assets/file-export.svg"
                  transition="scale-transition"
                  style="filter: brightness(0) invert(1)"
                  height="24px"
                  width="24px"
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <span v-if="editedDrivingItem.gnetData && editedDrivingItem.gnet_reservation_creator == 1">
                {{ $t('tooltips.sentViaGnet') }}
              </span>
              <span
                v-if="
                  editedDrivingItem?.affiliate?.affiliate_data_record && editedDrivingItem?.affiliate?.child_driving_id
                ">
                {{ $t('tooltips.sentViaAffiliate') }}
              </span>
            </v-tooltip>

            <v-spacer />

            <driving-options
              :key="disable"
              :editedDrivingItem="editedDrivingItem"
              :gnetDrivingStatusAccepted="gnetDrivingStatusAccepted"
              :gnetDrivingStatusRejected="gnetDrivingStatusRejected"
              :affiliateDrivingStatusAccepted="affiliateDrivingStatusAccepted"
              :affiliateDrivingStatusRejected="affiliateDrivingStatusRejected"
              :disable="disable"
              @duplicate="duplicate"
              @makeReturnTrip="makeReturnTrip"
              @openGnetModal="gnetModal = true"
              @openAffiliateModal="affiliateModal = true"
              @openCancelModal="cancelDrivingDialog = !cancelDrivingDialog"
              @openFinishModal="finishDrivingDialog = !finishDrivingDialog"
              @openNoShowModal="noShowDrivingDialog = !noShowDrivingDialog"
              @openDeleteDialog="deleteDrivingDialog = !deleteDrivingDialog"
              @addAttachment="addAttachment" />

            <v-icon class="mdi mdi-close mx-1" style="color: white" @click="selectedOpen = false"></v-icon>
          </v-toolbar>
        </div>
        <validation-observer ref="drivingForm" v-slot="{ handleSubmit, failed }" @dblclick="handleDoubleClick">
          <v-form lazy-validation @submit.prevent="handleSubmit(saveDriving)">
            <div class="sticky-header tabs d-flex align-center">
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12" class="d-sm-flex align-center">
                    <v-tabs v-model="activeTab" scrollable show-arrows :key="activeTab">
                      <v-tab :disabled="failed && activeTab === 1" @click.prevent="selectTab(0)">
                        {{ $t('driving.general') }}
                      </v-tab>
                      <v-tab :disabled="failed && activeTab === 0" @click.prevent="selectTab(1)">
                        {{ $t('driving.checkpoints') }}
                      </v-tab>
                      <v-tab :disabled="failed && [0, 1].includes(activeTab)" @click.prevent="selectTab(2)">
                        {{ $t('driving.passengers') }}
                      </v-tab>
                      <v-tab :disabled="failed && [0, 1].includes(activeTab)" @click.prevent="selectTab(3)">
                        {{ $t('driving.attachments') }}
                      </v-tab>
                      <v-tab :disabled="!disable" v-if="editedDrivingItem.id">
                        {{ $t('driving.costs') }}
                      </v-tab>
                      <v-tab :disabled="!disable" v-if="editedDrivingItem.id">
                        {{ $t('driving.comments') }}
                      </v-tab>
                    </v-tabs>
                    <div
                      cols="12"
                      sm="2"
                      v-if="[0, 1, 2, 3].includes(activeTab) && !disable"
                      class="d-flex align-center justify-end pr-4 mt-5 mt-sm-0">
                      <button-submit
                        :failed="disable || failed"
                        :loading="loadingDriving"
                        buttonText="buttons.save"></button-submit>
                    </div>
                    <div
                      cols="12"
                      sm="2"
                      v-if="
                        [0, 1, 2, 3].includes(activeTab) &&
                        disable &&
                        getStatusId() !== 5 &&
                        isGnetDrivingAccepted &&
                        isAffiliateDrivingAccepted
                      "
                      class="d-flex align-center justify-end pr-4 mt-5 mt-sm-0">
                      <v-btn class="primary" text type="button" @click="editItem">
                        {{ $t('buttons.edit') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div class="scrollable-content">
              <v-tabs-items v-model="activeTab">
                <v-tab-item>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" hidden md="6" sm="12">
                          <v-text-field v-model="editedDrivingItem.id" :label="$t('driving.id')"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="12">
                          <gmap-autocomplete
                            :options="googleMapsOptions"
                            :setFieldsTo="GoogleApiFields"
                            :value="editedDrivingItem.from_location"
                            selectFirstOnEnter
                            @place_changed="selectFromAddress">
                            <template v-slot:default="slotProps">
                              <validation-provider rules="required" v-slot="{ errors }" name="location_from">
                                <v-text-field
                                  ref="input"
                                  clearable
                                  :autofocus="editedDrivingItem.from_location_name ? false : true"
                                  v-model="editedDrivingItem.from_location_name"
                                  :disabled="disable"
                                  :label="$t('driving.locationFrom')"
                                  @focusout="selectFromAddressFocusout"
                                  v-on:attrs="slotProps.attrs"
                                  v-on:listeners="slotProps.listeners"
                                  :error-messages="errors"
                                  @keydown.enter.prevent
                                  >-
                                </v-text-field>
                              </validation-provider>
                            </template>
                          </gmap-autocomplete>
                        </v-col>
                        <v-col
                          v-if="editedDrivingItem.driving_type_id === 1 || transferOrDaily === 1"
                          cols="12"
                          lg="6"
                          md="6"
                          sm="12">
                          <gmap-autocomplete
                            :options="googleMapsOptions"
                            :setFieldsTo="GoogleApiFields"
                            :value="editedDrivingItem.to_location"
                            selectFirstOnEnter
                            slot-ref-name="input2"
                            @place_changed="selectToAddress">
                            <template v-slot:default="slotProps">
                              <validation-provider rules="required" v-slot="{ errors }" name="location_to">
                                <v-text-field
                                  ref="input2"
                                  clearable
                                  v-model="editedDrivingItem.to_location_name"
                                  :disabled="disable"
                                  :label="$t('driving.locationTo')"
                                  @focusout="selectToAddressFocusout"
                                  v-on:attrs="slotProps.attrs"
                                  v-on:listeners="slotProps.listeners"
                                  :error-messages="errors"
                                  @keydown.enter.prevent
                                  >-
                                </v-text-field>
                              </validation-provider>
                            </template>
                          </gmap-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                          <v-autocomplete
                            v-model="editedDrivingItem.vehicle_class_id"
                            :disabled="disable"
                            :items="filteredVehicleClasses"
                            clearable
                            item-text="name"
                            item-value="id"
                            :label="$t('driving.vehicleClass')"
                            @change="getAllVehiclesByClass(editedDrivingItem.vehicle_class_id)"
                            :no-data-text="$t('select.noDataAvailable')"
                            @focus="$event.target.click()"></v-autocomplete>
                        </v-col>
                        <v-col v-if="showVehicles" cols="12" lg="6" md="6" sm="12">
                          <v-autocomplete
                            v-model="editedDrivingItem.vehicle_id"
                            :disabled="disable"
                            :items="vehicles"
                            clearable
                            item-text="name"
                            item-value="id"
                            :label="$t('driving.vehicle')"
                            @change="checkIfVehicleIsAvailable"
                            :no-data-text="$t('select.noDataAvailable')"
                            @focus="$event.target.click()"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                          <v-autocomplete
                            v-model="editedDrivingItem.user_id"
                            :disabled="disable"
                            :items="filteredDrivers"
                            clearable
                            item-text="profile.full_name"
                            item-value="id"
                            :label="$t('driving.driver')"
                            @change="checkIfDriverIsAvailable"
                            :no-data-text="$t('select.noDataAvailable')"
                            @focus="$event.target.click()"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="12">
                          <v-menu
                            ref="pickupTime"
                            v-model="pickupTime"
                            :close-on-content-click="false"
                            :return-value.sync="editedDrivingItem.pickup_time"
                            min-width="auto"
                            offset-y
                            transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <validation-provider rules="required" v-slot="{ errors }" name="pickup_time">
                                <v-text-field
                                  v-model="editedDrivingItem.pickup_time"
                                  clearable
                                  :disabled="disable"
                                  :label="$t('driving.pickupTime')"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :error-messages="errors"></v-text-field>
                              </validation-provider>
                            </template>
                            <DatePicker
                              v-model="editedDrivingItem.pickup_time"
                              :minute-increment="1"
                              :model-config="modelConfig"
                              class="no-border"
                              is24hr
                              :first-day-of-week="firstDay"
                              mode="dateTime">
                            </DatePicker>
                            <div class="date-picker-buttons">
                              <v-btn class="primary cancel mr-2" elevation="2" text @click="pickupTime = false">
                                {{ $t('buttons.cancel') }}
                              </v-btn>
                              <v-btn class="primary mr-2" elevation="2" text @click="updatePickupTime">
                                {{ $t('buttons.confirm') }}
                              </v-btn>
                            </div>
                          </v-menu>
                        </v-col>
                        <v-container>
                          <v-row>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <v-autocomplete
                                v-model="editedDrivingItem.client_id"
                                :disabled="disable"
                                :items="filteredClients"
                                clearable
                                item-text="name"
                                item-value="id"
                                :label="$t('driving.chooseClient')"
                                :no-data-text="$t('select.noDataAvailable')"
                                @focus="$event.target.click()"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <v-checkbox
                                v-model="editedDrivingItem.client_confirmed"
                                :disabled="disable"
                                color="success"
                                :label="$t('driving.clientConfirmed')"></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-col cols="12" md="12" sm="12">
                          <validation-provider rules="max:1000" v-slot="{ errors }" name="description">
                            <v-textarea
                              v-model="editedDrivingItem.note"
                              :disabled="disable"
                              clearable
                              counter="1000"
                              :label="$t('driving.noteDriver')"
                              rows="2"
                              auto-grow
                              :error-messages="errors"></v-textarea>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <validation-provider rules="max:1000" v-slot="{ errors }" name="description">
                            <v-textarea
                              v-model="editedDrivingItem.note_dispatcher"
                              :disabled="disable"
                              clearable
                              counter="1000"
                              :label="$t('driving.noteDispatcher')"
                              rows="2"
                              auto-grow
                              :error-messages="errors"></v-textarea>
                          </validation-provider>
                        </v-col>

                        <v-expansion-panels v-model="panel" fluid>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              {{ $t('driving.price') }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row>
                                <v-col v-if="editedDrivingItem.message" cols="12" lg="12" md="12" sm="12">
                                  <p class="text-body-2">
                                    {{ $t('driving.drivingNotAccepted') }}
                                    {{ editedDrivingItem.message }}
                                  </p>
                                </v-col>
                                <v-col cols="12" lg="4" md="4" sm="6">
                                  <validation-provider
                                    rules="required|numeric_decimal|min_value:0"
                                    v-slot="{ errors }"
                                    name="price"
                                    class="d-flex align-center justify-space-between">
                                    <v-text-field
                                      v-model="editedDrivingItem.price"
                                      :disabled="disable"
                                      clearable
                                      :label="$t('driving.price')"
                                      type="text"
                                      :error-messages="errors"></v-text-field>
                                    <v-tooltip left :disabled="disable">
                                      <template v-slot:activator="{ on, attrs }">
                                        <div style="display: block" v-bind="attrs" v-on="on">
                                          <v-btn
                                            class="mb-2 ms-5 btn-small"
                                            :disabled="disable || disableCalculatePriceViaZonePricing()"
                                            @click="calculatePriceViaZonePricing()">
                                            <v-icon>mdi-cash</v-icon>
                                          </v-btn>
                                        </div>
                                      </template>
                                      <span
                                        v-if="
                                          editedDrivingItem?.driving_type_id === 2 ||
                                          editedDrivingItem?.drivingType?.id === 2
                                        ">
                                        {{ $t('driving.cannotCalculatePriceViaZonePricingForDailyRents') }}
                                      </span>
                                      <span v-else-if="disableCalculatePriceViaZonePricing()">
                                        {{ $t('driving.disableCalculatePriceViaZonePricing') }}
                                      </span>
                                      <span v-else>
                                        {{ $t('driving.calculatePriceViaZonePricing') }}
                                      </span>
                                    </v-tooltip>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="12" lg="4" md="4" sm="6">
                                  <validation-provider rules="required" v-slot="{ errors }" name="currency">
                                    <v-autocomplete
                                      v-model="editedDrivingItem.currency_id"
                                      :disabled="disable"
                                      :items="filteredCurrencies"
                                      clearable
                                      item-text="name"
                                      item-value="id"
                                      :label="$t('driving.currency')"
                                      :error-messages="errors"
                                      :no-data-text="$t('select.noDataAvailable')"
                                      @focus="$event.target.click()"></v-autocomplete>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="12" lg="4" md="4" sm="12">
                                  <validation-provider rules="required" v-slot="{ errors }" name="payment_method">
                                    <v-autocomplete
                                      v-model="editedDrivingItem.payment_method_id"
                                      :disabled="disable"
                                      :items="filteredPaymentMethodes"
                                      clearable
                                      item-text="name"
                                      item-value="id"
                                      :label="$t('driving.paymentMethod')"
                                      :error-messages="errors"
                                      :no-data-text="$t('select.noDataAvailable')"
                                      @focus="$event.target.click()"></v-autocomplete>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="12" lg="4" md="4" sm="12">
                                  <validation-provider
                                    rules="numeric|min_value:0"
                                    v-slot="{ errors }"
                                    name="commission_amount">
                                    <v-text-field
                                      v-model="editedDrivingItem.commission_amount"
                                      :disabled="disable"
                                      clearable
                                      :label="$t('driving.commissionAmount')"
                                      type="text"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="12" lg="4" md="4" sm="12">
                                  <validation-provider rules="required" v-slot="{ errors }" name="price_type">
                                    <v-autocomplete
                                      name="price_type"
                                      :disabled="disable"
                                      clearable
                                      item-text="name"
                                      item-value="id"
                                      v-model="editedDrivingItem.price_type"
                                      :items="priceTypes"
                                      :label="$t('driving.priceType')"
                                      :error-messages="errors"></v-autocomplete>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="12" lg="4" md="4" sm="12">
                                  <validation-provider
                                    rules="numeric_decimal|min_value:0"
                                    v-slot="{ errors }"
                                    name="vat">
                                    <v-text-field
                                      v-model="editedDrivingItem.vat_percentage"
                                      :disabled="disable"
                                      clearable
                                      :label="$t('driving.vatPercentage')"
                                      type="text"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>

                          <v-expansion-panel class="mt-5">
                            <v-expansion-panel-header>
                              {{ $t('driving.extraInfo') }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row>
                                <v-col v-if="editedDrivingItem.message" cols="12" lg="12" md="12" sm="12">
                                  <p class="text-body-2">
                                    {{ $t('driving.drivingNotAccepted') }}
                                    {{ editedDrivingItem.message }}
                                  </p>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="6">
                                  <v-menu
                                    ref="confirmationUntil"
                                    v-model="confirmationUntil"
                                    :close-on-content-click="false"
                                    :return-value.sync="editedDrivingItem.confirmation_until"
                                    min-width="auto"
                                    offset-y
                                    transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="editedDrivingItem.confirmation_until"
                                        clearable
                                        :disabled="disable"
                                        :label="$t('driving.acceptUntil')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"></v-text-field>
                                    </template>
                                    <DatePicker
                                      v-model="editedDrivingItem.confirmation_until"
                                      :minute-increment="1"
                                      :model-config="modelConfig"
                                      class="no-border"
                                      is24hr
                                      :first-day-of-week="firstDay"
                                      mode="dateTime">
                                    </DatePicker>
                                    <div class="date-picker-buttons">
                                      <v-btn
                                        class="primary cancel mr-2"
                                        elevation="2"
                                        text
                                        @click="confirmationUntil = false">
                                        {{ $t('buttons.cancel') }}
                                      </v-btn>
                                      <v-btn
                                        class="primary mr-2"
                                        elevation="2"
                                        text
                                        @click="$refs.confirmationUntil.save(editedDrivingItem.confirmation_until)">
                                        {{ $t('buttons.confirm') }}
                                      </v-btn>
                                    </div>
                                  </v-menu>
                                </v-col>
                                <v-col cols="6" lg="3" md="3" sm="6">
                                  <v-menu
                                    ref="expectedDuration"
                                    v-model="expectedDuration"
                                    :close-on-content-click="false"
                                    :return-value.sync="editedDrivingItem.duration"
                                    min-width="auto"
                                    offset-y
                                    transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="editedDrivingItem.duration"
                                        :disabled="disable"
                                        :label="$t('driving.expectedTime')"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"></v-text-field>
                                    </template>
                                    <DatePicker
                                      id="expectedDuration"
                                      v-model="editedDrivingItem.duration"
                                      :minute-increment="1"
                                      :model-config="timeModelConfig"
                                      class="no-border"
                                      is24hr
                                      mode="time"
                                      :first-day-of-week="firstDay"
                                      @focusout="updatePrice">
                                    </DatePicker>
                                    <div class="date-picker-buttons">
                                      <v-btn
                                        class="primary cancel mx-2"
                                        elevation="2"
                                        text
                                        @click="expectedDuration = false">
                                        {{ $t('buttons.cancel') }}
                                      </v-btn>
                                      <v-btn
                                        class="primary mr-2"
                                        elevation="2"
                                        text
                                        @click="$refs.expectedDuration.save(editedDrivingItem.duration)">
                                        {{ $t('buttons.confirm') }}
                                      </v-btn>
                                    </div>
                                  </v-menu>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" sm="6">
                                  <validation-provider
                                    rules="numeric|min_value:0"
                                    v-slot="{ errors }"
                                    name="waiting_time">
                                    <v-text-field
                                      v-model="editedDrivingItem.num_of_waiting_hours"
                                      :disabled="disable"
                                      clearable
                                      :label="$t('driving.waitingTime')"
                                      type="text"
                                      @focusout="updatePrice"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-col>

                                <v-col
                                  v-if="editedDrivingItem.driving_type_id === 1 || transferOrDaily === 1"
                                  cols="12"
                                  lg="6"
                                  md="6"
                                  sm="6">
                                  <v-menu
                                    ref="expectedDropOffTime"
                                    v-model="expectedDropOffTime"
                                    :close-on-content-click="false"
                                    :return-value.sync="editedDrivingItem.expected_drop_off_time"
                                    min-width="auto"
                                    offset-y
                                    transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="editedDrivingItem.expected_drop_off_time"
                                        :disabled="disable"
                                        clearable
                                        :label="$t('driving.dropOffTime')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"></v-text-field>
                                    </template>
                                    <DatePicker
                                      v-model="editedDrivingItem.expected_drop_off_time"
                                      :minute-increment="1"
                                      :model-config="modelConfig"
                                      class="no-border"
                                      is24hr
                                      :first-day-of-week="firstDay"
                                      mode="dateTime">
                                    </DatePicker>
                                    <div class="date-picker-buttons">
                                      <v-btn
                                        class="primary cancel mr-2"
                                        elevation="2"
                                        text
                                        @click="expectedDropOffTime = false">
                                        {{ $t('buttons.cancel') }}
                                      </v-btn>
                                      <v-btn
                                        class="primary mr-2"
                                        elevation="2"
                                        text
                                        @click="
                                          $refs.expectedDropOffTime.save(editedDrivingItem.expected_drop_off_time)
                                        ">
                                        {{ $t('buttons.confirm') }}
                                      </v-btn>
                                    </div>
                                  </v-menu>
                                </v-col>
                                <v-col
                                  v-if="editedDrivingItem.driving_type_id === 1 || transferOrDaily === 1"
                                  cols="12"
                                  lg="6"
                                  md="6"
                                  sm="6">
                                  <v-menu
                                    ref="expectedComebackTime"
                                    v-model="expectedComebackTime"
                                    :close-on-content-click="false"
                                    :return-value.sync="editedDrivingItem.expected_comeback_time"
                                    min-width="auto"
                                    offset-y
                                    transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="editedDrivingItem.expected_comeback_time"
                                        clearable
                                        :disabled="disable"
                                        :label="$t('driving.comebackTime')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"></v-text-field>
                                    </template>
                                    <DatePicker
                                      v-model="editedDrivingItem.expected_comeback_time"
                                      :minute-increment="1"
                                      :model-config="modelConfig"
                                      class="no-border"
                                      is24hr
                                      :first-day-of-week="firstDay"
                                      mode="dateTime">
                                    </DatePicker>
                                    <div class="date-picker-buttons">
                                      <v-btn
                                        class="primary cancel mr-2"
                                        elevation="2"
                                        text
                                        @click="expectedComebackTime = false">
                                        {{ $t('buttons.cancel') }}
                                      </v-btn>
                                      <v-btn
                                        class="primary mr-2"
                                        elevation="2"
                                        text
                                        @click="
                                          $refs.expectedComebackTime.save(editedDrivingItem.expected_comeback_time)
                                        ">
                                        {{ $t('buttons.confirm') }}
                                      </v-btn>
                                    </div>
                                  </v-menu>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="6">
                                  <validation-provider
                                    rules="required|numeric_decimal|min_value:0|max:255"
                                    v-slot="{ errors }"
                                    name="distance"
                                    ><v-text-field
                                      v-model="editedDrivingItem.distance"
                                      clearable
                                      :disabled="disable"
                                      :label="
                                        editedDrivingItem?.distance_unit == 'mi'
                                          ? $t('driving.distanceMi')
                                          : $t('driving.distance')
                                      "
                                      type="text"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="12">
                                  <validation-provider
                                    :rules="{ max: 255, regex: /^[A-Z,a-z]{1,3}\d{1,5}$/ }"
                                    v-slot="{ errors }"
                                    name="flight_number"
                                    class="d-flex align-center justify-space-between">
                                    <v-text-field
                                      v-model="editedDrivingItem.flight_number"
                                      clearable
                                      :disabled="disable"
                                      :label="$t('driving.flightNumber')"
                                      type="text"
                                      :error-messages="errors"></v-text-field>

                                    <flight-info
                                      :flightNumber="editedDrivingItem.flight_number"
                                      :disabled="errors.length > 0 || !editedDrivingItem.flight_number" />
                                  </validation-provider>
                                </v-col>

                                <v-col cols="12" lg="12" md="12" sm="12">
                                  <validation-provider rules="max:255" v-slot="{ errors }" name="waiting_board_text">
                                    <v-text-field
                                      v-model="editedDrivingItem.waiting_board_text"
                                      clearable
                                      :disabled="disable"
                                      :label="$t('driving.waitingBoardText')"
                                      type="text"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-col>

                                <v-col cols="12" lg="12" md="12" sm="12">
                                  <v-checkbox
                                    v-model="editedDrivingItem.paid"
                                    :disabled="disable"
                                    color="success"
                                    class="mt-0"
                                    :label="$t('driving.paid')"></v-checkbox>

                                  <v-checkbox
                                    v-model="editedDrivingItem.mandatory"
                                    :disabled="disable"
                                    color="success"
                                    class="mt-0"
                                    :label="$t('driving.drivingRequired')"></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>

                          <gnet-info
                            v-if="editedDrivingItem.gnetData"
                            :editedDrivingItem="editedDrivingItem"
                            :filteredCurrencies="filteredCurrencies"
                            :gnetDrivingStatusAccepted="gnetDrivingStatusAccepted"
                            :gnetDrivingStatusRejected="gnetDrivingStatusRejected"
                            @gnetDrivingStatusAccepted="gnetDrivingStatusAccepted = true"
                            @gnetDrivingStatusRejected="gnetDrivingStatusRejected = true" />

                          <affiliate-info
                            v-if="editedDrivingItem?.affiliate?.affiliate_data_record"
                            :editedDrivingItem="editedDrivingItem"
                            :filteredCurrencies="filteredCurrencies"
                            :affiliateDrivingStatusAccepted="affiliateDrivingStatusAccepted"
                            :affiliateDrivingStatusRejected="affiliateDrivingStatusRejected"
                            @affiliateDrivingStatusAccepted="affiliateDrivingStatusAccepted = true"
                            @affiliateDrivingStatusRejected="affiliateDrivingStatusRejected = true" />
                        </v-expansion-panels>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item>
                  <add-checkpoints
                    :editedDrivingItem="editedDrivingItem"
                    :checkpoints="checkpoints"
                    :headers="checkpointsHeaders"
                    :disable="disable"
                    :googleMapsOptions="googleMapsOptions"
                    :GoogleApiFields="GoogleApiFields"
                    :timeModelConfig="timeModelConfig"
                    @updateCheckpoints="updateCheckpoints"
                    @deleteCheckpoint="deleteCheckpoint"
                    @calculateDistance="calculateDistanceBetweenMultipleLocations"></add-checkpoints>
                </v-tab-item>

                <v-tab-item>
                  <add-passengers
                    :key="disable"
                    :editedDrivingItem="editedDrivingItem"
                    :passengers="passengers"
                    :headers="passengersHeaders"
                    :disable="disable"
                    :drivers="drivers"
                    @updatePassengers="updatePassengers"
                    @deletePassenger="deletePassenger"
                    @checkSeatsAvailability="handleAddingNewPassenger"></add-passengers>
                </v-tab-item>

                <v-tab-item>
                  <add-files
                    :editedItem="editedDrivingItem"
                    :attachments="editedDrivingItem.attachments"
                    :filesToSend="filesToSend"
                    :disable="disable"
                    deleteRoute="drivings/deleteDrivingAttachment"
                    @addNewFile="addNewFile"
                    @deleteNewFile="deleteNewFile"
                    @deleteOldFile="deleteOldFile"></add-files>
                </v-tab-item>

                <v-tab-item v-if="editedDrivingItem.id && disable">
                  <ExpensesList
                    :expensesFromDrivingOrder="expenses"
                    :drivingId="editedDrivingItem.id"
                    :vehicles="vehicles"
                    @loadDrivingDetails="loadDrivingDetails" />
                </v-tab-item>

                <v-tab-item v-if="editedDrivingItem.id && disable">
                  <v-card-text>
                    <v-container>
                      <validation-observer :key="activeTab" ref="drivingCommentForm" v-slot="{ handleSubmit, failed }">
                        <v-form lazy-validation @submit.prevent="handleSubmit(saveComment)">
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <validation-provider
                                :rules="drivingCloning ? 'max:1000' : 'required|max:1000'"
                                v-slot="{ errors }"
                                name="comment">
                                <v-textarea
                                  v-model="editedDrivingComment.body"
                                  clearable
                                  counter="1000"
                                  :label="$t('driving.comment')"
                                  rows="2"
                                  auto-grow
                                  :error-messages="errors"></v-textarea>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-card-actions>
                                <v-spacer />
                                <button-submit
                                  :failed="failed"
                                  :loading="loadingComment"
                                  buttonText="buttons.save"></button-submit>
                              </v-card-actions>
                            </v-col>
                            <v-col
                              v-for="comment in editedDrivingItem.comments"
                              :key="comment.id"
                              cols="12"
                              md="12"
                              sm="12">
                              <v-banner outlined rounded shaped single-line sticky>
                                <p>
                                  <b>{{ comment?.user?.profile?.full_name }}</b>
                                  <span style="float: right">{{ comment?.created_at }}</span>
                                </p>
                                <p style="text-wrap: initial">{{ comment.body }}</p>
                                <p v-if="parseInt(comment.rate)">Ocjena: {{ comment.rate }}</p>
                              </v-banner>
                            </v-col>
                          </v-row>
                        </v-form>
                      </validation-observer>
                    </v-container>
                  </v-card-text>
                </v-tab-item>
              </v-tabs-items>

              <v-card-text
                color="white"
                class="pt-0"
                v-if="editedDrivingItem?.created_at && editedDrivingItem?.userCreated?.full_name">
                <v-container>
                  <v-row>
                    <v-col class="py-0 pe-0">
                      <p
                        class="mb-0 mt-1 text-right d-block smaller-font"
                        v-html="
                          $t('driving.createdBy', {
                            time: editedDrivingItem?.created_at,
                            user: editedDrivingItem?.userCreated?.full_name || editedDrivingItem?.userCreated?.username,
                          })
                        "></p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </div>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="gnetModal"
      :close-on-content-click="false"
      max-width="800px"
      offset-x
      :key="'gnetModal' + gnetModal">
      <v-card color="white lighten-4" flat min-width="350px">
        <div class="sticky-header">
          <v-toolbar class="primary" dark>
            <v-toolbar-title>
              {{ $t('driving.gnet') }}
            </v-toolbar-title>

            <v-spacer />
            <v-icon class="mdi mdi-close mx-1" style="color: white" @click="gnetModal = false"></v-icon>
          </v-toolbar>
        </div>

        <div class="scrollable-content">
          <v-card-text>
            <gnet-form
              :editedDrivingItem="editedDrivingItem"
              @closeGnetModal="gnetModal = false"
              @closeDrivingModal="selectedOpen = false"
              @loadAllDrivings="$emit('loadAllDrivings')" />
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="affiliateModal"
      :close-on-content-click="false"
      max-width="800px"
      offset-x
      :key="'affiliateModal' + affiliateModal">
      <v-card color="white lighten-4" flat min-width="350px">
        <div class="sticky-header">
          <v-toolbar class="primary" dark>
            <v-toolbar-title>
              {{ $t('affiliate.affiliate') }}
            </v-toolbar-title>

            <v-spacer />
            <v-icon class="mdi mdi-close mx-1" style="color: white" @click="affiliateModal = false"></v-icon>
          </v-toolbar>
        </div>

        <div class="scrollable-content">
          <v-card-text>
            <affiliate-form
              :editedDrivingItem="editedDrivingItem"
              @closeAffiliateModal="affiliateModal = false"
              @closeDrivingModal="selectedOpen = false"
              @loadAllDrivings="$emit('loadAllDrivings')" />
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <div class="tourStyle">
      <v-tour name="myTour" :steps="stepsTour"></v-tour>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import { checkpointsHeaders } from '@/mixins/data-table-headers';
import { passengersHeaders } from '@/mixins/data-table-headers';
import { defaultDriving, defaultDrivingComment } from '@/mixins/default-items';
import AddFiles from '@/components/AddFiles.vue';
import AddPassengers from '@/components/drivings/AddPassengers.vue';
import AddCheckpoints from '@/components/drivings/AddCheckpoints.vue';
import FlightInfo from '@/components/drivings/FlightInfo.vue';
import ExpensesList from '@/views/pages/expenses/ExpensesList.vue';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { DatePicker } from 'v-calendar/src/components';
import { formatDateTime, formatDateTimeToDDMMHHmm, formatDateTimeToMMDDHHmm } from '@/utils/formatDate';
import GnetInfo from '@/components/drivings/GnetInfo.vue';
import GnetForm from '@/components/drivings/GnetForm.vue';
import AffiliateInfo from '@/components/drivings/AffiliateInfo.vue';
import AffiliateForm from '@/components/drivings/AffiliateForm.vue';
import store from '@/store';
import DrivingOptions from '@/components/drivings/DrivingOptions.vue';

export default {
  name: 'DrivingModal',
  components: {
    DatePicker,
    AddFiles,
    AddPassengers,
    AddCheckpoints,
    FlightInfo,
    ButtonSubmit,
    ExpensesList,
    GnetInfo,
    GnetForm,
    AffiliateInfo,
    AffiliateForm,
    DrivingOptions,
  },
  props: ['selectedElement', 'transferOrDaily'],
  data: () => ({
    activeTab: 0,
    value: '',
    driving: {},
    selectedOpen: false,
    drivingFormValid: true,
    drivingCommentFormValid: true,
    editedDrivingItem: { comments: [], attachments: [], checkpoints: [], passengers: [] },
    editedDrivingComment: {},
    enableDrivingCommentSave: true,
    enableDrivingSave: true,
    currencies: [],
    filteredCurrencies: [],
    paymentMethods: [],
    filteredPaymentMethodes: [],
    vehicleClasses: [],
    filteredVehicleClasses: [],
    vehicle: {},
    formTitle: 'driving.newTransfer',
    vehicles: [],
    drivers: [],
    filteredDrivers: [],
    GoogleApiFields: ['name', 'formatted_address', 'geometry', 'place_id', 'plus_code', 'url'],
    pickupTime: null,
    expectedDropOffTime: null,
    expectedComebackTime: null,
    confirmationUntil: null,
    expectedDuration: null,
    vehicleAvailabilityDialog: false,
    vehicleAvailabilityDialogTitle: 'driving.vehicleIsUnavailable',
    vehicleSeatAvailabilityDialog: false,
    vehicleSeatAvailabilityDialogTitle: 'driving.checkVehicleSeatAvailability',
    driverAvailabilityDialog: false,
    cancelDrivingDialog: false,
    finishDrivingDialog: false,
    noShowDrivingDialog: false,
    deleteDrivingDialog: false,
    panel: [1, 2, 3, 4],
    disable: true,
    googleMapsOptions: {
      bounds: {
        north: 45.830329,
        south: 41.51274,
        east: 20.834725,
        west: 16.332208,
      },
      // componentRestrictions: {country: ["me", "hr", "rs", "ba", "al"]},
      strictBounds: false,
    },
    min: null,
    max: null,
    modelConfig: {
      type: 'string',
      mask:
        store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
          ? 'MM-DD-YYYY HH:mm'
          : 'DD-MM-YYYY HH:mm',
    },
    timeModelConfig: {
      type: 'string',
      mask: 'HH:mm',
    },
    filesToSend: [],
    checkpoints: [],
    passengers: [],
    expenses: [],
    loadingDriving: false,
    loadingComment: false,
    loadingCancel: false,
    loadingFinish: false,
    loadingNoShow: false,
    loadingDelete: false,
    showVehicles: false,
    clients: [],
    filteredClients: [],
    stepsTour: [],
    drivingCloning: false,
    gnetDrivingStatusAccepted: false,
    gnetDrivingStatusRejected: false,
    affiliateDrivingStatusAccepted: false,
    affiliateDrivingStatusRejected: false,
    user: {},
    gnetModal: false,
    affiliateModal: false,
    priceTypes: [
      { id: 'NET', name: i18n.t('advancedSettings.NET') },
      { id: 'GROSS', name: i18n.t('advancedSettings.GROSS') },
    ],
  }),
  mounted() {},
  created() {
    this.editedDrivingItem = Object.assign({}, defaultDriving);
    this.editedDrivingComment = Object.assign({}, defaultDrivingComment);
    this.filesToSend = [];
    this.checkpoints = [];
    this.passengers = [];
    this.getAllVehicleClasses();
    this.getAllCurrencies();
    this.getAllPaymentMethods();
    this.getAllDrivers();
    this.getAllClients();

    this.editedDrivingItem.driving_type_id = this.transferOrDaily;
    if (this.transferOrDaily === 1) {
      this.formTitle = 'driving.newDriving';
      this.editedDrivingItem.duration = null;
    } else {
      this.formTitle = 'driving.newDailyRent';
      this.editedDrivingItem.duration = '8:00';
    }

    this.user = this.$store.getters['auth/user'];
  },
  computed: {
    checkpointsHeaders() {
      return checkpointsHeaders(i18n);
    },
    passengersHeaders() {
      return passengersHeaders(i18n);
    },

    isGnetDrivingAccepted() {
      return (
        this.gnetDrivingStatusAccepted ||
        !this.editedDrivingItem.gnetData ||
        (this.editedDrivingItem.gnetData && this.editedDrivingItem.gnet_reservation_accepted == 1)
      );
    },

    isAffiliateDrivingAccepted() {
      return (
        this.affiliateDrivingStatusAccepted ||
        !this.editedDrivingItem?.affiliate?.affiliate_data_record ||
        !this.editedDrivingItem?.affiliate?.parent_driving_id ||
        (this.editedDrivingItem?.affiliate?.affiliate_data_record &&
          this.editedDrivingItem?.affiliate?.status == 'accepted')
      );
    },
  },
  methods: {
    async getAllVehiclesByClass(vehicle_class_id) {
      await this.$store
        .dispatch('vehicles/getAllVehicles', {
          vehicle_class_id: vehicle_class_id || '',
        })
        .then((res) => {
          if (vehicle_class_id) {
            this.showVehicles = true;
          } else {
            this.showVehicles = false;
          }
          this.vehicles = res.data?.filter((item) => {
            if (
              item.active == '1' ||
              item.active == 1 ||
              item.active == true ||
              item?.id === this.editedDrivingItem?.vehicle_id
            ) {
              return item;
            }
          });
          let clearOutVehicle = true;
          for (let vehicle of res.data) {
            if (parseInt(vehicle.id) === parseInt(this.editedDrivingItem.vehicle_id)) {
              clearOutVehicle = false;
              break;
            }
          }
          if (clearOutVehicle) {
            this.editedDrivingItem.vehicle_id = null;
          }
        });
    },

    async getAllDrivers() {
      await this.$store.dispatch('users/getAllUsers', { drivers: true }).then((res) => {
        this.drivers = res.data.length
          ? res.data.map((user) => {
              user.full_name = user.profile?.full_name || 'N/A';
              return user;
            })
          : [];
      });

      this.filterAllDrivers();
    },

    filterAllDrivers() {
      this.filteredDrivers = this.drivers?.filter((item) => {
        if (item.active == '1' || item?.id === this.editedDrivingItem?.user_id) {
          return item;
        }
      });
    },

    async getAllVehicleClasses(id) {
      await this.$store
        .dispatch('vehicleClasses/getAllVehicleClasses', {
          vehicle_class_id: id,
        })
        .then((res) => {
          this.vehicleClasses = res.data;
        });

      this.filterAllVehicleClasses();
    },

    filterAllVehicleClasses() {
      this.filteredVehicleClasses = this.vehicleClasses?.filter((item) => {
        if (item.active == '1' || item?.id === this.editedDrivingItem?.vehicle_class_id) {
          return item;
        }
      });
    },

    async getAllCurrencies() {
      await this.$store.dispatch('currencies/getOrganisationCurrencies').then((res) => {
        this.currencies = res.data.map((item) => {
          return item.currency;
        });
      });

      if (!this.editedDrivingItem?.id) {
        this.filterAllCurrencies();
      }
    },

    filterAllCurrencies() {
      this.filteredCurrencies = this.currencies?.filter((item) => {
        if (item.active == '1' || item?.id === this.editedDrivingItem?.currency_id) {
          return item;
        }
      });
    },

    async getAllPaymentMethods() {
      await this.$store.dispatch('paymentMethods/getAllPaymentMethods').then((res) => {
        this.paymentMethods = res.data;
      });

      if (!this.editedDrivingItem?.id) {
        this.filterAllPaymentMethods();
      }
    },

    filterAllPaymentMethods() {
      this.filteredPaymentMethodes = this.paymentMethods?.filter((item) => {
        if (item.active == '1' || item?.id === this.editedDrivingItem?.payment_method_id) {
          return item;
        }
      });
    },

    async getAllClients() {
      await this.$store.dispatch('clients/getAllClients').then((res) => {
        this.clients = res.data;
      });

      this.filterAllClients();
    },

    filterAllClients() {
      this.filteredClients = this.clients?.filter((item) => {
        if (item.active == '1' || item?.id === this.editedDrivingItem?.client?.id) {
          return item;
        }
      });
    },

    editItem() {
      if ([4, 5].includes(this.activeTab)) {
        this.activeTab = 0;
      }
      this.disable = false;
    },

    showEvent(event) {
      if (!event.id) {
        this.disable = false;
      }
      this.panel = [];

      const user = this.$store.getters['auth/user'];

      if (event?.id) {
        this.selectedOpen = true;
        this.editedDrivingItem = Object.assign({}, event);
        this.editedDrivingComment = Object.assign({}, defaultDrivingComment);
        this.editedDrivingComment.driving_id = parseInt(event.id);
        this.editedDrivingItem.to_location = JSON.parse(event?.to_location);
        this.editedDrivingItem.from_location = event.from_location ? JSON.parse(event.from_location) : null;
        this.editedDrivingItem.to_location_name = this.editedDrivingItem.to_location?.name;
        this.editedDrivingItem.confirmation_until = formatDateTime(event.drivingProposal?.confirmation_until);
        this.editedDrivingItem.from_location_name = this.editedDrivingItem.from_location?.name;
        this.editedDrivingItem.payment_method_id = event.paymentMethod?.id || this.paymentMethods[0]?.id;
        this.editedDrivingItem.currency_id = event.currency?.id || this.currencies[0]?.id;
        this.editedDrivingItem.vehicle_class_id = event?.vehicleClass?.id
          ? parseInt(event?.vehicleClass?.id)
          : event.vehicle?.vehicleClass?.id
          ? parseInt(event.vehicle?.vehicleClass?.id)
          : null;

        this.editedDrivingItem.user_id = parseInt(event.drivingProposal?.user_id);
        this.editedDrivingItem.mandatory = parseInt(event.drivingProposal?.mandatory);
        this.editedDrivingItem.driving_type_id = parseInt(event.drivingType?.id);
        this.editedDrivingItem.num_of_waiting_hours = event.num_of_waiting_hours
          ? parseInt(event.num_of_waiting_hours)
          : 0;
        this.editedDrivingItem.commission_amount = event.commission_amount ? parseInt(event.commission_amount) : 0;
        this.editedDrivingItem.price = event.price ? parseFloat(event.price) : 0;
        this.getAllVehiclesByClass(this.editedDrivingItem.vehicle_class_id);
        this.editedDrivingItem.vehicle_id = parseInt(event.vehicle?.id);
        this.editedDrivingItem.client_confirmed = event?.client_confirmed == '1' ? true : false;
        this.filesToSend = [];
        this.editedDrivingItem.client_id = parseInt(event.client?.id);
        this.editedDrivingItem.paid = parseInt(event.paid);

        this.loadDrivingDetails();

        this.disable = true;

        this.editedDrivingItem.userCreated = {
          ...this.editedDrivingItem.userCreated,
          full_name:
            this.editedDrivingItem.userCreated?.full_name !== ' '
              ? this.editedDrivingItem.userCreated?.full_name
              : this.editedDrivingItem.userCreated?.username,
        };

        this.editedDrivingItem.distance_unit = user?.organisation?.organisation_settings?.distance_unit;
      } else {
        this.editedDrivingItem = Object.assign({}, { ...this.selectedElement, driving_type_id: this.transferOrDaily });

        this.disable = false;
        this.transferOrDailyDialog = true;
        this.editedDrivingItem.payment_method_id = this.filteredPaymentMethodes[0]?.id;
        this.editedDrivingItem.currency_id = this.filteredCurrencies[0]?.id;

        this.editedDrivingItem.paid = user?.organisation?.organisation_settings?.drivings_paid;
        this.editedDrivingItem.mandatory = user?.organisation?.organisation_settings?.drivings_mandatory;
        this.editedDrivingItem.distance_unit = user?.organisation?.organisation_settings?.distance_unit;
        this.editedDrivingItem.price_type = user?.organisation?.organisation_settings?.default_price_type || 'NET';
        this.editedDrivingItem.vat_percentage = user?.organisation?.organisation_settings?.vat_percentage || 0;
      }
    },

    async loadDrivingDetails() {
      this.$store
        .dispatch('drivingComment/loadDrivingDetails', { driving_id: this.editedDrivingItem.id })
        .then((res) => {
          this.editedDrivingItem.comments = res.data.comments.reverse();
          this.editedDrivingItem.attachments = res.data.attachments;
          this.checkpoints = res.data.drivingCheckpoints.map((item) => {
            return {
              ...item,
              checkpoint_location: JSON.parse(item?.location),
              checkpoint_location_name: JSON.parse(item?.location)?.name ?? JSON.parse(item?.location)?.description,
              geometry: JSON.parse(item?.location)?.geometry,
            };
          });
          this.passengers = res.data.passengers;
          this.expenses = res.data.expenses;
        })
        .catch(() =>
          this.$store.dispatch('showSnackbar', {
            text: i18n.t('snackbar.anErrorOccured'),
            color: 'red',
          })
        );
    },

    async selectTab(tab) {
      // if view mode, normally switch tabs
      if (this.disable) {
        this.activeTab = tab;
      } else {
        // if edit mode and first tab active, validate form before switching tabs
        if (this.activeTab == 0) {
          // to prevent changing tab without pickup_time, if confirm is not clicked on date picker modal
          if (this.editedDrivingItem.pickup_time) {
            // to prevent checking for vehicle and driver availability after changing tab
            this.updatePickupTime({ doNotUpdateDates: true });
          }
          if (await this.$refs.drivingForm.validate()) {
            this.activeTab = tab;
          } else {
            this.activeTab = 0;
          }
          // if edit mode and second tab active, validate form before switching tabs
        } else if (this.activeTab == 1) {
          if (await this.$refs.drivingForm.validate()) {
            this.activeTab = tab;
          } else {
            this.activeTab = 1;
          }
        } else {
          this.activeTab = 0;
        }
      }
    },

    createFormData() {
      let formData = new FormData();

      formData.append('id', this.editedDrivingItem.id ? this.editedDrivingItem.id : '');
      formData.append('number', this.editedDrivingItem.number ? this.editedDrivingItem.number : '');
      formData.append('currency_id', this.editedDrivingItem.currency_id ? this.editedDrivingItem.currency_id : '');
      formData.append(
        'payment_method_id',
        this.editedDrivingItem.payment_method_id ? this.editedDrivingItem.payment_method_id : ''
      );
      formData.append('from_location', JSON.stringify(this.editedDrivingItem.from_location));
      this.editedDrivingItem.driving_type_id === 1 &&
        formData.append('to_location', JSON.stringify(this.editedDrivingItem.to_location));
      formData.append('note', this.editedDrivingItem.note ? this.editedDrivingItem.note : '');
      formData.append(
        'note_dispatcher',
        this.editedDrivingItem.note_dispatcher ? this.editedDrivingItem.note_dispatcher : ''
      );
      formData.append(
        'pickup_time',
        this.editedDrivingItem.pickup_time ? formatDateTimeToDDMMHHmm(this.editedDrivingItem.pickup_time) : ''
      );
      formData.append('distance', this.editedDrivingItem.distance ? this.editedDrivingItem.distance : 0);
      formData.append(
        'flight_number',
        this.editedDrivingItem.flight_number ? this.editedDrivingItem.flight_number : ''
      );
      formData.append(
        'waiting_board_text',
        this.editedDrivingItem.waiting_board_text ? this.editedDrivingItem.waiting_board_text : ''
      );
      formData.append('duration', this.editedDrivingItem.duration ? this.editedDrivingItem.duration : '');
      formData.append(
        'expected_drop_off_time',
        this.editedDrivingItem.expected_drop_off_time
          ? formatDateTimeToDDMMHHmm(this.editedDrivingItem.expected_drop_off_time)
          : ''
      );
      formData.append(
        'expected_comeback_time',
        this.editedDrivingItem.expected_comeback_time
          ? formatDateTimeToDDMMHHmm(this.editedDrivingItem.expected_comeback_time)
          : ''
      );
      formData.append('price', this.editedDrivingItem.price ? this.editedDrivingItem.price : 0);
      formData.append('paid', this.editedDrivingItem.paid ? 1 : 0);
      formData.append(
        'vehicle_id',
        this.editedDrivingItem.vehicle_id && this.editedDrivingItem.vehicle_class_id
          ? this.editedDrivingItem.vehicle_id
          : ''
      );
      formData.append('user_id', this.editedDrivingItem.user_id ? this.editedDrivingItem.user_id : '');
      formData.append('mandatory', this.editedDrivingItem.mandatory ? 1 : 0);
      formData.append(
        'confirmation_until',
        this.editedDrivingItem.confirmation_until
          ? formatDateTimeToDDMMHHmm(this.editedDrivingItem.confirmation_until)
          : ''
      );
      formData.append(
        'to_location_name',
        this.editedDrivingItem.to_location_name ? this.editedDrivingItem.to_location_name : ''
      );
      formData.append(
        'from_location_name',
        this.editedDrivingItem.from_location_name ? this.editedDrivingItem.from_location_name : ''
      );
      formData.append('message', this.editedDrivingItem.message ? this.editedDrivingItem.message : '');
      formData.append(
        'num_of_waiting_hours',
        this.editedDrivingItem.num_of_waiting_hours ? this.editedDrivingItem.num_of_waiting_hours : ''
      );
      formData.append(
        'commission_amount',
        this.editedDrivingItem.commission_amount ? this.editedDrivingItem.commission_amount : ''
      );
      formData.append(
        'driving_type_id',
        this.editedDrivingItem.driving_type_id ? this.editedDrivingItem.driving_type_id : ''
      );
      formData.append('client_confirmed', this.editedDrivingItem.client_confirmed ? 1 : 0);
      formData.append(
        'vehicle_class_id',
        this.editedDrivingItem.vehicle_class_id ? this.editedDrivingItem.vehicle_class_id : ''
      );
      this.filesToSend.map((item) => {
        formData.append('attachments[]', item);
      });

      this.checkpoints.map((item) => {
        formData.append('checkpoints[]', JSON.stringify(item));
      });
      formData.append('passengers', JSON.stringify(this.getFilteredPassengers(this.passengers)));
      formData.append('client_id', this.editedDrivingItem.client_id ? this.editedDrivingItem.client_id : '');
      formData.append('price_type', this.editedDrivingItem.price_type ? this.editedDrivingItem.price_type : 'NET');
      formData.append(
        'vat_percentage',
        this.editedDrivingItem.vat_percentage ? this.editedDrivingItem.vat_percentage : 0
      );

      return formData;
    },

    getFilteredPassengers(items) {
      // Trim the values in each object
      const trimmedArray = items.map((obj) => {
        const trimmedObj = {};
        for (const key in obj) {
          if (typeof obj[key] === 'string') {
            trimmedObj[key] = obj[key].trim();
          } else {
            trimmedObj[key] = obj[key];
          }
        }
        return trimmedObj;
      });

      const filteredArray = trimmedArray.filter((obj) => {
        // Check if any property in the object is not an empty string
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] !== '') {
            return true; // Keep the object if any property is not empty
          }
        }
        return false; // Remove the object if all properties are empty
      });

      return filteredArray;
    },

    saveDriving(doNotCloseModal = false) {
      // to prevent saving without pickup_time, if confirm is not clicked on date picker modal
      if (this.editedDrivingItem.pickup_time) {
        // to prevent checking for vehicle and driver availability after save
        this.updatePickupTime({ doNotUpdateDates: true });
      }

      if (this.editedDrivingItem.distance) {
        this.editedDrivingItem.distance = parseFloat(Number(this.editedDrivingItem.distance).toFixed(1));
      }
      if (this.editedDrivingItem.price) {
        this.editedDrivingItem.price = parseFloat(Number(this.editedDrivingItem.price).toFixed(2));
      }
      this.enableDrivingSave = false;
      this.loadingDriving = true;

      let formData = this.createFormData();

      let path = 'drivings/saveDriving';

      if (this.editedDrivingItem?.affiliate?.child_driving_id) {
        path = 'drivings/updateAffiliateDriving';
      }

      this.$store
        .dispatch(path, formData)
        .then((res) => {
          this.editedDrivingItem.id = res.data.id;
          this.editedDrivingItem.number = res.data?.number;
          this.editedDrivingItem.confirmation_until = formatDateTime(res.data.drivingProposal?.confirmation_until);
          this.editedDrivingItem.drivingProposal = { ...res.data.drivingProposal };
          this.editedDrivingItem.color = res.data.color;
          this.editedDrivingItem.client = res.data.client ? { ...res.data.client } : null;
          this.editedDrivingItem.pickup_time_original = res.data.pickup_time;
          this.editedDrivingItem.vehicle = res.data.vehicle;
          this.editedDrivingItem.driving_number = res.data.number + ` [${res.data.public_number}]`;
          this.editedDrivingItem.userCreated = {
            ...res.data.userCreated,
            full_name: res.data.userCreated?.profile?.full_name || res.data.userCreated?.username,
          };
          this.editedDrivingItem.created_at = formatDateTime(res.data.created_at);

          if (doNotCloseModal) {
            this.editedDrivingItem.id = '';
            this.disable = false;
            this.selectedOpen = true;
            this.editedDrivingItem.number = null;
            this.editedDrivingItem.driving_number = null;
            this.formTitle =
              this.editedDrivingItem?.drivingType?.id == '2' || this.editedDrivingItem.driving_type_id == '2'
                ? 'driving.newDailyRent'
                : 'driving.newDriving';
            this.editedDrivingItem.color = '';
            this.drivingCloning = false;
            this.editedDrivingItem.gnetData = null;
            this.editedDrivingItem.gnet_reservation_accepted = null;
            this.editedDrivingItem.gnet_reservation_creator = null;
            this.editedDrivingItem.gnet_reservation_number = null;

            this.editedDrivingItem.affiliate = null;
          } else {
            this.disable = true;
          }

          if (res.gnet_sync_success == false) {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.savedSuccessfullyWithGnetError'),
              color: 'green',
            });
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          }

          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableDrivingSave = true;
          this.loadingDriving = false;
        });
    },
    selectFromAddress(input) {
      this.editedDrivingItem.from_location = input;
      this.editedDrivingItem.from_location_name = input.name + ` (${input.formatted_address})`;
      this.calculateDistanceBetweenMultipleLocations();
    },
    selectToAddress(input2) {
      this.editedDrivingItem.to_location = input2;
      this.editedDrivingItem.to_location_name = input2.name + ` (${input2.formatted_address})`;
      this.calculateDistanceBetweenMultipleLocations();
    },

    calculateDistanceBetweenMultipleLocations() {
      this.$store
        .dispatch('googleMapsAPI/getDistanceBetweenMultipleLocations', {
          from: this.editedDrivingItem.from_location,
          to: this.editedDrivingItem.to_location,
          checkpoints: this.checkpoints,
        })
        .then((data) => {
          if (data) {
            let durationSeconds = data?.totalDuration;

            this.editedDrivingItem.distance =
              this.editedDrivingItem?.distance_unit == 'mi'
                ? parseFloat(((data?.totalDistance / 1000) * 0.621371).toFixed(1))
                : parseFloat((data?.totalDistance / 1000).toFixed(1));
            if (this.editedDrivingItem.price == 0 || !this.editedDrivingItem.price) {
              this.editedDrivingItem.price = parseFloat((this.editedDrivingItem.distance * 0.7).toFixed(2));
            }
            if (durationSeconds) {
              let hours = Math.floor(durationSeconds / 3600)
                .toString()
                .padStart(2, '0');
              let minutes = Math.floor((durationSeconds % 3600) / 60)
                .toString()
                .padStart(2, '0');
              this.editedDrivingItem.duration = `${hours}:${minutes}`;
              this.updateTimes();
            }
          }
        });
    },
    selectFromAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedDrivingItem.from_location?.name &&
        value !==
          this.editedDrivingItem.from_location?.name + ` (${this.editedDrivingItem.from_location?.formatted_address})`
      ) {
        this.editedDrivingItem.from_location = { name: value };
        this.editedDrivingItem.from_location_name = value;
      }
    },
    selectToAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedDrivingItem.to_location?.name &&
        value !==
          this.editedDrivingItem.to_location?.name + ` (${this.editedDrivingItem.to_location?.formatted_address})`
      ) {
        this.editedDrivingItem.to_location = { name: value };
        this.editedDrivingItem.to_location_name = value;
      }
    },

    updatePickupTime(options) {
      this.$refs.pickupTime.save(this.editedDrivingItem.pickup_time);

      if (!options.doNotUpdateDates) {
        this.updateTimes();
      }
    },

    updateTimes() {
      if (this.editedDrivingItem.duration) {
        this.updatePrice();
      }
      if (parseInt(this.editedDrivingItem.driving_type_id) === 1) {
        if (this.editedDrivingItem.duration && this.editedDrivingItem.pickup_time) {
          let durationArr = this.editedDrivingItem.duration.split(':');
          let durationSeconds = durationArr[0] * 3600 + durationArr[1] * 60;
          if (durationSeconds !== 0 && typeof durationSeconds === 'number' && isNaN(durationSeconds) === false) {
            let initialTime =
              store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
                ? formatDateTimeToDDMMHHmm(this.editedDrivingItem.pickup_time)
                : this.editedDrivingItem.pickup_time;

            initialTime = initialTime.split('-');

            let tempDays = initialTime[0];
            initialTime[0] = initialTime[1];
            initialTime[1] = tempDays;
            initialTime = initialTime.join('/');
            let dateNumber = Date.parse(initialTime);
            let dateObject = new Date(dateNumber);
            dateObject.setSeconds(durationSeconds);
            let dropOffTime = dateObject.toLocaleString('en-GB').replaceAll('/', '-').replace(',', '');

            this.editedDrivingItem.expected_drop_off_time =
              dropOffTime === 'Invalid Date'
                ? null
                : store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
                ? formatDateTimeToMMDDHHmm(dropOffTime)
                : dropOffTime;
            dateObject.setSeconds(durationSeconds);
            let comebackTime = dateObject.toLocaleString('en-GB').replaceAll('/', '-').replace(',', '');
            this.editedDrivingItem.expected_comeback_time =
              comebackTime === 'Invalid Date'
                ? null
                : store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
                ? formatDateTimeToMMDDHHmm(comebackTime)
                : comebackTime;
          } else {
            this.editedDrivingItem.expected_drop_off_time = this.editedDrivingItem.pickup_time;
            this.editedDrivingItem.expected_comeback_time = this.editedDrivingItem.pickup_time;
          }
        }
      }

      this.checkIfDriverIsAvailable(this.editedDrivingItem.user_id);
      this.checkIfVehicleIsAvailable(this.editedDrivingItem.vehicle_id);
    },
    async checkIfDriverIsAvailable(value) {
      if (this.editedDrivingItem.pickup_time && value) {
        this.enableDrivingSave = false;
        let data = { id: value };
        data.pickupTime = formatDateTimeToDDMMHHmm(this.editedDrivingItem.pickup_time);
        if (this.editedDrivingItem.expected_drop_off_time) {
          data.expectedDropOffTime = formatDateTimeToDDMMHHmm(this.editedDrivingItem.expected_drop_off_time);
        }
        if (this.editedDrivingItem.id) {
          data.driving_id = this.editedDrivingItem.id;
        }
        await this.$store
          .dispatch('users/checkIfDriverIsAvailable', data)
          .then((res) => {
            this.driverAvailabilityDialog = res.result;
          })
          .catch(() => {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          })
          .finally(() => (this.enableDrivingSave = true));
      }
    },
    async checkIfVehicleIsAvailable(value) {
      this.checkVehicleSeatAvailability(this.editedDrivingItem.vehicle_id, this.passengers);
      if (this.editedDrivingItem.pickup_time && value) {
        this.updatePrice();
        let data = { id: value };
        this.enableDrivingSave = false;
        data.pickupTime = formatDateTimeToDDMMHHmm(this.editedDrivingItem.pickup_time);
        if (this.editedDrivingItem.expected_drop_off_time) {
          data.expectedDropOffTime = formatDateTimeToDDMMHHmm(this.editedDrivingItem.expected_drop_off_time);
        }
        if (this.editedDrivingItem.id) {
          data.driving_id = this.editedDrivingItem.id;
        }
        await this.$store
          .dispatch('vehicles/checkIfVehicleIsAvailable', data)
          .then((res) => {
            if (res.vehicleUnderMaintenance) {
              this.vehicleAvailabilityDialogTitle = 'driving.vehicleIsDamaged';
            } else {
              this.vehicleAvailabilityDialogTitle = 'driving.vehicleIsUnavailable';
            }
            this.vehicleAvailabilityDialog = res.result;
          })
          .catch(() => {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          })
          .finally(() => (this.enableDrivingSave = true));
      }
    },
    async checkVehicleSeatAvailability(value, items) {
      if (value) {
        this.enableDrivingSave = false;

        let data = { id: value, passengers: items };

        await this.$store
          .dispatch('vehicles/checkVehicleSeatAvailability', data)
          .then((res) => {
            this.vehicleSeatAvailabilityDialogTitle = 'driving.vehicleSeatNumberIsUnavailable';
            this.vehicleSeatAvailabilityDialog = !res.status;
          })
          .catch(() => {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          })
          .finally(() => (this.enableDrivingSave = true));
      }
    },
    clearOutVehicle() {
      this.vehicleAvailabilityDialog = false;
      this.vehicleSeatAvailabilityDialog = false;
      this.editedDrivingItem.vehicle_id = null;
      this.editedDrivingItem = { ...this.editedDrivingItem, vehicle_id: null };
    },
    clearOutDriver() {
      this.driverAvailabilityDialog = false;
      this.editedDrivingItem.user_id = null;
      this.editedDrivingItem = { ...this.editedDrivingItem, user_id: null };
    },
    async saveComment() {
      if (this.$refs.drivingCommentForm.validate()) {
        this.enableDrivingCommentSave = false;
        this.loadingComment = true;
        await this.$store
          .dispatch('drivingComment/saveDrivingComment', {
            ...this.editedDrivingComment,
            driving_id: this.editedDrivingItem.id,
          })
          .then((res) => {
            this.editedDrivingItem.comments.unshift(res.data);
            this.$store.commit('updatedNewDrivingCounter');
            this.editedDrivingComment = Object.assign(
              {},
              { ...defaultDrivingComment, driving_id: this.editedDrivingItem.id }
            );
            this.$refs.drivingCommentForm.reset();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          })
          .catch((error) => {
            if (error.response?.status === 422) {
              this.$store
                .dispatch('errorMessages/errorMapper', error.response.data.message)
                .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
            } else {
              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
            }
          })
          .finally(() => {
            this.enableDrivingCommentSave = true;
            this.loadingComment = false;
          });
      }
    },
    async cancelDriving() {
      this.loadingCancel = true;
      await this.$store
        .dispatch('drivings/cancelDriving', this.editedDrivingItem)
        .then((res) => {
          if (res.gnet_sync_success == false) {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.savedSuccessfullyWithGnetError'),
              color: 'green',
            });
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.drivingCanceled'), color: 'green' });
          }
          this.selectedOpen = false;
          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.loadingCancel = false;
          this.cancelDrivingDialog = false;
        });
    },
    async finishDriving() {
      this.loadingFinish = true;
      this.updatePrice();
      const body = {
        id: this.editedDrivingItem.id,
        num_of_waiting_hours: parseInt(this.editedDrivingItem.num_of_waiting_hours.toString(), 10),
      };
      if (this.editedDrivingItem.price) {
        body.price = parseFloat(this.editedDrivingItem.price.toFixed(2));
      }
      await this.$store
        .dispatch('drivings/finishDriving', body)
        .then((res) => {
          if (res.gnet_sync_success == false) {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.savedSuccessfullyWithGnetError'),
              color: 'green',
            });
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.drivingFinished'), color: 'green' });
          }
          this.selectedOpen = false;
          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loadingFinish = false;
          this.finishDrivingDialog = false;
        });
    },
    async noShowDriving() {
      this.loadingNoShow = true;

      const body = {
        id: this.editedDrivingItem.id,
      };

      await this.$store
        .dispatch('drivings/noShowDriving', body)
        .then((res) => {
          if (res.gnet_sync_success == false) {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.savedSuccessfullyWithGnetError'),
              color: 'green',
            });
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.drivingNoShow'), color: 'green' });
          }
          this.selectedOpen = false;
          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loadingNoShow = false;
          this.noShowDrivingDialog = false;
        });
    },
    async deleteDriving() {
      this.loadingDelete = true;
      await this.$store
        .dispatch('drivings/deleteDriving', this.editedDrivingItem)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.drivingDeleted'), color: 'green' });
          this.selectedOpen = false;
          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.loadingDelete = false;
          this.deleteDrivingDialog = false;
        });
    },
    updatePrice() {
      if (
        this.editedDrivingItem.vehicle_id &&
        parseInt(this.editedDrivingItem.vehicle_id) !== parseInt(this.vehicle?.id)
      ) {
        this.vehicle = this.vehicles.filter((e) => parseInt(e.id) === parseInt(this.editedDrivingItem.vehicle_id))[0];
      }
      if (this.editedDrivingItem.price == 0 || !this.editedDrivingItem.price) {
        if (this.editedDrivingItem.vehicle_id && this.vehicle?.id) {
          let price = 0;
          if (this.editedDrivingItem.driving_type_id) {
            if (this.vehicle?.price_per_waiting_hour && this.editedDrivingItem.num_of_waiting_hours) {
              price = this.vehicle?.price_per_waiting_hour * this.editedDrivingItem.num_of_waiting_hours;
            }
            if (this.editedDrivingItem.duration && this.vehicle?.price_per_hour) {
              let durationAsInteger = 0;
              let durationArr = this.editedDrivingItem.duration.split(':');
              durationAsInteger = Number(durationArr[0]) + Number(durationArr[1]) / 60;
              price += this.vehicle?.price_per_hour * durationAsInteger;
            }
          } else {
            if (this.vehicle?.price_per_km && this.editedDrivingItem.distance) {
              price = parseFloat((this.vehicle?.price_per_km * this.editedDrivingItem.distance).toFixed(2));
            }
          }
          if (price) {
            this.editedDrivingItem.price = parseFloat(price.toFixed(2));
          }
        }
      }
    },
    handleAddingNewPassenger(items) {
      this.checkVehicleSeatAvailability(this.editedDrivingItem.vehicle_id, items);
    },
    updateCheckpoints(items) {
      this.checkpoints = [...items];
    },
    deleteCheckpoint(index) {
      this.checkpoints.splice(index, 1);
      this.calculateDistanceBetweenMultipleLocations();
    },
    updatePassengers(items) {
      this.passengers = [...items];
    },
    deletePassenger(index) {
      this.passengers.splice(index, 1);
    },
    addNewFile(item) {
      this.filesToSend = [...this.filesToSend, item];
    },
    deleteOldFile(index) {
      this.editedDrivingItem.attachments.splice(index, 1);
    },
    deleteNewFile(index) {
      this.filesToSend.splice(index, 1);
    },
    getStatusId() {
      const proposal = this.editedDrivingItem?.drivingProposal;
      let id;
      if (this.editedDrivingItem?.id && proposal) {
        id = parseInt(proposal.driving_proposal_status_id);
      }

      return id;
    },
    getStatus() {
      const drivingProposalStatus = {
        1: 'drivingStatus.pending',
        2: 'drivingStatus.accepted',
        3: 'drivingStatus.rejected',
        4: 'drivingStatus.expired',
        5: 'drivingStatus.canceled',
        6: 'drivingStatus.draft',
        7: 'drivingStatus.done',
        12: 'drivingStatus.noShow',
      };

      const proposal = this.editedDrivingItem?.drivingProposal;
      let id;
      if (this.editedDrivingItem?.id && proposal) {
        id = parseInt(proposal.driving_proposal_status_id);
      }

      return drivingProposalStatus[id] ? i18n.t(drivingProposalStatus[id]) : '';
    },
    handleDoubleClick() {
      if (this.getStatusId() !== 5 && this.isGnetDrivingAccepted && this.isAffiliateDrivingAccepted) {
        this.editItem();
      }
    },

    startTour() {
      this.$tours['myTour'].start();
      this.toggleTour(true);
    },
    async duplicate() {
      this.drivingCloning = true;
      // Delay the validation process using setTimeout
      setTimeout(async () => {
        if (await this.$refs.drivingForm.validate()) {
          this.saveDriving(true);
        }
      });
    },
    async makeReturnTrip() {
      this.drivingCloning = true;
      // Delay the validation process using setTimeout
      setTimeout(async () => {
        if (await this.$refs.drivingForm.validate()) {
          this.saveDriving(true);
          if (this.editedDrivingItem.driving_type_id === 1) {
            [this.editedDrivingItem.from_location, this.editedDrivingItem.to_location] = [
              this.editedDrivingItem.to_location,
              this.editedDrivingItem.from_location,
            ];
            [this.editedDrivingItem.from_location_name, this.editedDrivingItem.to_location_name] = [
              this.editedDrivingItem.to_location_name,
              this.editedDrivingItem.from_location_name,
            ];
            this.checkpoints = [...this.checkpoints.reverse()];
            this.passengers = [
              ...this.passengers.map((item) => {
                return { ...item, id: null };
              }),
            ];
          }
        }
      });
    },
    addAttachment(file) {
      this.editedDrivingItem = {
        ...this.editedDrivingItem,
        attachments: [...this.editedDrivingItem.attachments, file],
      };
    },

    async calculatePriceViaZonePricing() {
      if (
        this.editedDrivingItem.from_location &&
        this.editedDrivingItem.to_location &&
        this.editedDrivingItem.currency_id &&
        this.editedDrivingItem.vehicle_class_id
      ) {
        const data = {
          from_lat: JSON.parse(JSON.stringify(this.editedDrivingItem.from_location)).geometry.location.lat,
          from_lng: JSON.parse(JSON.stringify(this.editedDrivingItem.from_location)).geometry.location.lng,
          to_lat: JSON.parse(JSON.stringify(this.editedDrivingItem.to_location)).geometry.location.lat,
          to_lng: JSON.parse(JSON.stringify(this.editedDrivingItem.to_location)).geometry.location.lng,
          currency_id: this.editedDrivingItem.currency_id,
          vehicle_class_id: this.editedDrivingItem.vehicle_class_id,
        };

        await this.$store
          .dispatch('zonePricing/calculatePriceViaZonePricing', data)
          .then((res) => {
            if (res.data !== null) {
              res.data.map((item) => {
                if (item.vehicle_class_id == this.editedDrivingItem.vehicle_class_id) {
                  this.editedDrivingItem.price = item.price;
                }
              });
            } else {
              this.$store.dispatch('showSnackbar', {
                text: i18n.t('snackbar.pricingZoneNoMatch'),
                color: '#ffeb3b',
                textColor: '#000',
              });
            }
          })
          .catch(() =>
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.anErrorOccured'),
              color: 'red',
            })
          );
      }
    },
    disableCalculatePriceViaZonePricing() {
      return (
        !this.editedDrivingItem.vehicle_class_id ||
        !this.editedDrivingItem.currency_id ||
        !this.editedDrivingItem.from_location_name ||
        !this.editedDrivingItem.to_location_name
      );
    },
  },
  watch: {
    selectedOpen(val) {
      this.modelConfig = {
        type: 'string',
        mask:
          store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
            ? 'MM-DD-YYYY HH:mm'
            : 'DD-MM-YYYY HH:mm',
      };

      if (!val) {
        this.paymentMethodDialog = false;
        this.editedDrivingItem = Object.assign({}, defaultDriving);
        this.editedDrivingComment = Object.assign({}, defaultDrivingComment);
        this.filesToSend = [];
        this.checkpoints = [];
        this.passengers = [];
        this.expenses = [];
        if (this.$refs.drivingCommentForm) {
          this.$refs.drivingCommentForm.reset();
        }
        if (this.$refs.drivingForm) {
          this.$refs.drivingForm.reset();
        }
        this.$refs.drivingForm.reset();
        this.activeTab = 0;
        this.filterAllDrivers();
        this.filterAllClients();
        this.filterAllVehicleClasses();
        this.filterAllCurrencies();
        this.filterAllPaymentMethods();

        this.user = this.$store.getters['auth/user'];
      } else {
        this.filterAllDrivers();
        this.filterAllClients();
        this.filterAllVehicleClasses();
        this.filterAllCurrencies();
        this.filterAllPaymentMethods();

        if (!this.editedDrivingItem.id && this.editedDrivingItem.pickup_time) {
          this.editedDrivingItem.pickup_time = formatDateTime(this.editedDrivingItem.pickup_time);

          this?.$refs?.pickupTime && this?.$refs?.pickupTime.save(this.editedDrivingItem.pickup_time);
        }

        this.gnetDrivingStatusAccepted = this.editedDrivingItem.gnet_reservation_accepted == 1 ? true : false;
        this.affiliateDrivingStatusAccepted = this.editedDrivingItem?.affiliate?.status == 'accepted' ? true : false;

        const proposal = this.editedDrivingItem?.drivingProposal;

        this.gnetDrivingStatusRejected =
          this.editedDrivingItem.gnet_reservation_accepted == 0 && proposal?.drivingProposalStatus == 'Rejected'
            ? true
            : false;

        this.affiliateDrivingStatusRejected =
          this.editedDrivingItem?.affiliate?.status == 'rejected' && proposal?.drivingProposalStatus == 'Rejected'
            ? true
            : false;

        this.user = this.$store.getters['auth/user'];
      }
    },
    selectedElement: {
      handler() {
        this.showEvent(this.selectedElement);

        this.selectedOpen = true;
      },
      deep: false,
    },
    transferOrDaily: {
      handler() {
        this.editedDrivingItem.driving_type_id = this.transferOrDaily;
        if (this.transferOrDaily === 1) {
          this.formTitle = 'driving.newDriving';
          this.editedDrivingItem.duration = null;
        } else {
          this.formTitle = 'driving.newDailyRent';
          this.editedDrivingItem.duration = '8:00';
        }
      },
    },
  },
};
</script>

<style>
#arrivalTime .vc-date,
#expectedDuration .vc-date {
  display: none;
}

#expectedDuration {
  border: none;
  border-radius: 0;
  background-color: #fff;
}

.time-pick-label {
  font-size: 12px;
  position: absolute;
  z-index: 2;
  margin-top: -1px;
  color: rgba(0, 0, 0, 0.6);
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: inherit;
}

.scrollable-content {
  overflow-y: auto;
  max-height: 70vh; /* Adjust according to your dialog size */
}

.sticky-header.tabs {
  background-color: #fff;
}

.smaller-font {
  font-size: 12px;
}

.v-icon.status-circle {
  font-size: 16px;
}

.vc-day-content {
  display: none;
}

@media only screen and (min-width: 600px) {
  .sticky-header .v-tabs {
    width: 50%;
  }
}
</style>
