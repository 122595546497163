import { getDistanceBetweenMultipleLocations, getLocationName, getCountryLocation } from '@/api/googleMapsAPI';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getDistanceBetweenMultipleLocations(state, item) {
      return getDistanceBetweenMultipleLocations(item);
    },

    async getLocationName(state, item) {
      return getLocationName(item);
    },

    async getCountryLocation(state, item) {
      return getCountryLocation(item);
    },
  },
};
