<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary transfers-map-btn" v-bind="attrs" v-on="on">
        <v-icon>mdi-map-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="info title white--text font-weight-bold">
        {{ $t('gpsMaps.drivers') }}
        <v-spacer />
        <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
      </v-card-title>
      <v-card-text class="pa-1 position-relative">
        <GmapMap
          v-if="center.lat && center.lng"
          :center="center"
          :zoom="9"
          map-type-id="roadmap"
          style="width: 100%; height: 600px; max-height: 70vh">
          <GmapMarker
            :key="'transfer' + index"
            v-for="(item, index) in markers"
            :position="item?.latest_gps_coordinate"
            :clickable="true"
            :draggable="false"
            @click="handlePinClicked(item)"
            :icon="generateMapIcon(item)" />
        </GmapMap>

        <driver-card
          v-if="activeDriver"
          :key="activeDriver?.id"
          :driver="activeDriver"
          :active="false"
          :clickable="false">
        </driver-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDateTime, formatTime } from '@/utils/formatDate';
import DriverCard from './DriverCard.vue';

export default {
  name: 'AllTransfersMap',
  props: [],
  components: { DriverCard },
  data: () => ({
    center: { lat: null, lng: null }, // Where to center map
    dialog: false,
    drivers: [],
    activeDriver: null,
  }),
  created() {
    this.loadAllDriversData();
  },
  computed: {
    markers() {
      // we must filter drivers and show pins for drivers that have coordinates
      return this.drivers.filter((driver) => {
        return (
          driver?.latest_gps_coordinate && driver?.latest_gps_coordinate?.lat && driver?.latest_gps_coordinate?.lng
        );
      });
    },
  },
  methods: {
    async loadAllDriversData() {
      await this.$store.dispatch('gpsMapAPI/getDriversData').then((res) => {
        this.drivers = res.data.map((item) => {
          return {
            ...item,
            // we format coordinate object beacuse of google maps
            latest_gps_coordinate: {
              lat: item?.latest_gps_coordinate?.latitude,
              lng: item?.latest_gps_coordinate?.longitude,
            },
            profile: {
              ...item?.profile,
              full_name:
                item?.profile?.first_name && item?.profile?.last_name
                  ? item?.profile?.first_name + ' ' + item?.profile?.last_name
                  : '',
            },
            driving: {
              ...item?.driving,
              pickup_time: formatTime(item?.driving?.pickup_time),
              expected_drop_off_time: formatTime(item?.driving?.expected_drop_off_time),
            },
            // we handle driver status here, based on backend response, because we use it in many files
            status: this.getDriverStatus(item),
            latest_gps_coordinate_time: formatDateTime(item?.latest_gps_coordinate?.created_at),
          };
        });
      });
    },
    generateMapIcon(item) {
      // we generate pin icon with color based on driver status
      let color;

      // we generate pin icon with color based on driver status
      if (item?.id === this.activeDriver?.id) {
        color = '#0727DB';
      } else {
        switch (item?.status) {
          case 'offline':
            color = '#9CA0C8';
            break;
          case 'available':
            color = '#3CB503';
            break;
          case 'inTransfer':
            color = '#007D00';
            break;
          default:
            color = '#3CB503';
            break;
        }
      }
      const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="${color}" d="M12,6.5A3.5,3.5 0 0,1 15.5,10A3.5,3.5 0 0,1 12,13.5A3.5,3.5 0 0,1 8.5,10A3.5,3.5 0 0,1 12,6.5M12,2A8,8 0 0,0 4,10C4,16 12,22 12,22C12,22 20,16 20,10A8,8 0 0,0 12,2Z" /></svg>`;
      return `data:image/svg+xml;base64,${btoa(svg)}`;
    },

    handlePinClicked(item) {
      this.center = item?.latest_gps_coordinate; // when we click on pin we center map in way that the pin is in center of it

      this.activeDriver = item; // To know on which pin we have clicked on
    },
    close() {
      this.dialog = false;
    },
    getDriverStatus(driver) {
      if (parseInt(driver?.allow_gps_tracking)) {
        if (driver?.driving?.driving_status) {
          // statuses from backend {1: 'Started', 2: 'Finished', 3: 'Ongoing', 4: 'Pending', 5: 'Canceled'};
          if ([1, 3].includes(driver?.driving?.driving_status)) {
            return 'inTransfer'; // If the driver is driving currently
          } else {
            return 'available'; // If the driver is not driving currently
          }
        } else {
          return 'available'; // If the driver has no driving order assigned
        }
      } else {
        return 'offline'; // If the driver he has turned GPS off
      }
    },
  },
  watch: {
    markers(val) {
      if (val.length && val[0]?.latest_gps_coordinate?.lat && val[0]?.latest_gps_coordinate?.lng) {
        // center map on first marker if marker exist
        this.center = { lat: val[0]?.latest_gps_coordinate?.lat, lng: val[0]?.latest_gps_coordinate?.lng };
      } else {
        // center map on random coordinates if there is no markers
        this.center = { lat: 1.1, lng: 1.1 };
      }
    },
    dialog(val) {
      if (val) {
        this.loadAllDriversData();
      } else {
        this.activeDriver = null;
        this.drivers = [];
      }
    },
  },
};
</script>

<style>
.transfers-map-btn {
  position: fixed;
  bottom: 20px;
  right: 100px;
  z-index: 9999;
  width: 60px !important;
  height: 60px !important;
  min-width: unset !important;
  border-radius: 50% !important;
}
</style>
